import { useSelector } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap"

import CardSwitch from "../../../app/components/CardSwitch"
import { COLLECTION_DOCUMENTS } from "../../../constant/constant";

export const Step3 = ({ state, setState }) => {
    const { list: listProcess } = useSelector(state => state.typeProcessSlice);
    
    return (
        <>
            <p className="mt-2">
                <span className="text-green fw-bold">3. </span>
                Configuraciones adicionales.
            </p>
            <div className="row">
                <div className="col-lg-4">
                    <CardSwitch
                        icon="fa-solid fa-camera text-green"
                        name="saveConfiguration"
                        title="¿Deseas guardar esta configuración?"
                        checked={state.saveAsDefault}
                        onChange={() => setState({ ...state, saveAsDefault: !state.saveAsDefault })}
                    />
                </div>
            </div>
            {
                state.collection === COLLECTION_DOCUMENTS
                &&
                <>
                    <div className="row">
                        <div className="col-lg-4">
                            <CardSwitch
                                icon="fa-solid fa-camera text-green"
                                name="createExpedients"
                                title={
                                    <div className="">
                                        ¿Deseas generar los expedientes respectivos?
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Popover id={`popover-positioned`}>
                                                    <Popover.Header as="h3" className='bg-green text-center'><span className='text-white'>Creación de expedientes</span></Popover.Header>
                                                    <Popover.Body>
                                                        Elije el <b>tipo de proceso</b> que se ajusta al tipo de documento que haz elegido, el documento elegido será identificado en los movimientos del expediente y si coinciden se generará el documento referenciado a este documento.
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <i className="fa-solid fa-circle-question ms-2 text-green" role='button'></i>
                                        </OverlayTrigger>
                                    </div>
                                }
                                checked={state.generateExpedients}
                                onChange={() => setState({ ...state, generateExpedients: !state.generateExpedients })}
                            />
                        </div>
                    </div>
                    {
                        state.generateExpedients
                        &&
                        <div className="row mt-2">
                            <div className="col-12 col-lg-4">
                                <label className="fw-bold">Tipo de proceso<i className="text-red">*</i></label>
                                <select
                                    className="form-select dark-green-input mb-1"
                                    name="type_process"
                                    value={state.typeProcess}
                                    onChange={(e) => setState({ ...state, typeProcess: e.target.value })}
                                    required
                                >
                                    <option value="">Seleccione...</option>
                                    {listProcess.map(process => <option value={process.id}>{process.name}</option>)}
                                </select>
                            </div>
                        </div>
                    }
                </>
            }
        </>
    )
}
