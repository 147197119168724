import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Close from "../../../assets/close.svg";
import MenuButton from "../../../assets/menu.svg";
import LegalDesign from "../../../assets/legal-design.svg";
import {
  setIsExpanded,
  setExpandDocument,
  setExpandDocumentToggle,
} from "../../../reducers/main/menuReducer";
import ItemNavigation from "./ItemNavigation";
import DrodownNavigation from "./DrodownNavigation";
import { startLogout } from "../../../reducers/auth/thunks";

const Menu = () => {
  const { isExpanded, expandDocument } = useSelector(state => state.menuSlice);
  const { canActionRoles } = useSelector((state) => state.loginSlice);
  const { company } = useSelector((state) => state.businessSlice);
  const dispatch = useDispatch();

  const openMenu = () => {
    if (isExpanded) {
      dispatch(setExpandDocument({ value: false }));
    }
    dispatch(setIsExpanded());
  };

  const openMenuAndOpcionDocument = () => {
    if (!isExpanded) {
      dispatch(setIsExpanded());
      dispatch(setExpandDocumentToggle());
      return;
    }
    dispatch(setExpandDocumentToggle());
  };

  return (
    <>
      {/* <div
        className={`border-right sidebar ${
          isExpanded ? "change-width" : "width-sidebar"
        }`}
      > */}
      <div
        className="sidebar-menu__layout"
      >
        <div
          className="button-menu p-0"
          title="Menú"
          onClick={() => openMenu()}
        >
          {!isExpanded ? (
            <img
              className="animate__animated animate__rotateIn animate__slow"
              src={MenuButton}
              alt=""
            />
          ) : (
            <img
              className="animate__animated animate__rotateIn animate__slow"
              src={Close}
              alt=""
            />
          )}
        </div>
        <hr className="text-white my-2" />

        {/* Logo de la aplicación y su nombre */}
        <NavLink to={"#/"} className="text-white">
          <div className="row px-3 mb-5">
            {isExpanded && (
              <>
                <div className="col-3 col-logo">
                  <img src={LegalDesign} alt="" />
                </div>
                <div className="col col-name-aplication">
                  <span className="fs-4 font-lilita-one">
                    {" "}
                    <strong> SORTOM </strong>
                  </span>
                </div>
              </>
            )}
          </div>
        </NavLink>

        <ItemNavigation
          icon="fa-solid fa-gauge-high"
          path="/"
          title="Dashboard"
          isExpanded={isExpanded}
        />
        <ItemNavigation
          icon="fa-solid fa-message"
          path="/chat"
          title="Chat"
          isExpanded={isExpanded}
        />
        {/* <ItemNavigation
                        icon="fa-solid fa-envelope-open-text"
                        path="/mails"
                        title="Correo"
                        isExpanded={isExpanded}
                    /> */}
        <ItemNavigation
          icon="fa-solid fa-user"
          path="/users/list"
          title="Usuarios"
          isExpanded={isExpanded}
        />
        {company.is_system_admin && (
          <ItemNavigation
            icon="fa-solid fa-landmark"
            path="/business/list"
            title="Empresas"
            isExpanded={isExpanded}
          />
        )}
        {canActionRoles && (
          <ItemNavigation
            icon="fa-solid fa-ranking-star"
            path="/roles/list"
            title="Roles"
            isExpanded={isExpanded}
          />
        )}
        <DrodownNavigation
          icon="fa-solid fa-person"
          title="Personas"
          isExpanded={isExpanded}
        >
          <ItemNavigation
            icon="fa-solid fa-list-check"
            path="/typepeople/list"
            title="Parametrizar"
            isExpanded={isExpanded}
            identation="ms-5"
          />
          <ItemNavigation
            icon="fa-solid fa-person-circle-plus"
            path="/people/list"
            title="Listar"
            isExpanded={isExpanded}
            identation="ms-5"
          />
        </DrodownNavigation>

        <DrodownNavigation
          icon="fa-solid fa-file-lines"
          title="Documentos"
          isExpanded={isExpanded}
        >
          <ItemNavigation
            icon="fa-solid fa-list-check"
            path="/documents-auto/list"
            title="Parametrizar"
            isExpanded={isExpanded}
            identation="ms-5"
          />
          <ItemNavigation
            icon="fa-solid fa-signature"
            path="/documents-auto/signatures"
            title="Firmas"
            isExpanded={isExpanded}
            identation="ms-5"
          />
          <ItemNavigation
            icon="fa-solid fa-bars-progress"
            path="/documents-auto/in-progress"
            title="En progreso"
            isExpanded={isExpanded}
            identation="ms-5"
          />
        </DrodownNavigation>

        {/* <DrodownNavigation
            icon="fa-solid fa-suitcase"
            title="Casos"
            isExpanded={isExpanded}
          >
            <ItemNavigation
              icon="fa-solid fa-list-check"
              path="/case-parameterization/list"
              title="Parametrizar"
              isExpanded={isExpanded}
              identation="ms-5"
            />
            <ItemNavigation
              icon="fa-solid fa-feather-pointed"
              path="/case/list"
              title="Crear"
              isExpanded={isExpanded}
              identation="ms-5"
            />
          </DrodownNavigation> */}

        <DrodownNavigation
          icon="fa-solid fa-file-invoice"
          title="Formularios"
          isExpanded={isExpanded}
        >
          <ItemNavigation
            icon="fa-solid fa-list-check"
            path="/form-parameterization/list"
            title="Parametrizar"
            isExpanded={isExpanded}
            identation="ms-5"
          />
          <ItemNavigation
            icon="fa-solid fa-signature"
            path="/process/virtual-notary/list"
            title="Firmas"
            isExpanded={isExpanded}
            identation="ms-5"
          />
        </DrodownNavigation>

        <DrodownNavigation
          icon="fa-solid fa-folder-closed"
          title="Expedientes"
          isExpanded={isExpanded}
        >
          <ItemNavigation
            icon="fa-solid fa-list-check"
            path="/type-process/list"
            title="Tipos proceso"
            isExpanded={isExpanded}
            identation="ms-5"
          />
          <ItemNavigation
            icon="fa-solid fa-folder"
            path="/expedient/list"
            title="Listar"
            isExpanded={isExpanded}
            identation="ms-5"
          />
        </DrodownNavigation>

        <ItemNavigation
          icon="fa-solid fa-calendar-days"
          path="/calendar"
          title="Calendario"
          isExpanded={isExpanded}
        />
        <ItemNavigation
          icon="fa-solid fa-file-import"
          path="/importers"
          title="Importaciones"
          isExpanded={isExpanded}
        />

        <hr className="text-white my-3" />

        <ItemNavigation
          icon="fa-solid fa-gear"
          path="/configurations"
          title="Configuración"
          isExpanded={isExpanded}
        />

        <div
          role="button"
          className="d-block p-2 text-white"
          onClick={() => dispatch(startLogout())}
        >
          {isExpanded ? (
            <>
              <i className="fa-solid fa-right-from-bracket fs-5 ms-3 me-2"></i>{" "}
              Salir
            </>
          ) : (
            <i className="fa-solid fa-right-from-bracket fs-5 mx-1"></i>
          )}
        </div>
      </div>
    </>
  );
};

export default Menu;
