import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import GeneralService from '../../../services/GeneralService';
import { HIDE, ID_NOT_DEFINED } from '../../../constant/constant';
import { MessageError } from '../../../utils/message';
import { toast } from '../../../utils/toast';
import { insertFromTemplate, updateFromTemplate } from '../../../reducers/configuration/reducerTemplateEmail';
import { useForm } from 'react-hook-form';

export const ModalTemplateEmail = ({ show, setShow }) => {
    const { selectedTemplate } = useSelector(state => state.templateEmailSlice);
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        const service = new GeneralService("template-email");

        if (selectedTemplate.id === ID_NOT_DEFINED) {
            const res = await service.post(data);
            if (res.is_ok) {
                setShow(HIDE);
                toast(dispatch, res.message);
                dispatch(insertFromTemplate(res.content));
                return;
            }
            MessageError(res.message, service.status);
            return;
        }

        const dataUpdate = {...data, id: selectedTemplate.id };
        const res = await service.update(dataUpdate);
        if (res.is_ok) {
            setShow(HIDE);
            toast(dispatch, res.message);
            dispatch(updateFromTemplate(dataUpdate));
            return;
        }
        MessageError(res.message, service.status);
    }

    const loadFormState = () => {
        reset({
            name: selectedTemplate.name,
            subject: selectedTemplate.subject,
            content: selectedTemplate.content,
        });
    }

    useEffect(() => {
        loadFormState();
    }, [selectedTemplate.id]);

    return (
        <Modal show={show} onHide={() => setShow(HIDE)}>
            <form onSubmit={handleSubmit(onSubmit)} autoCorrect="on">
                <Modal.Header className='bg-light-green pe-4 d-flex flex-row align-items-center' closeButton>
                    {
                        selectedTemplate.id === ID_NOT_DEFINED ?
                            <i className="fa-solid fa-circle-plus fs-5 me-2 animate__animated animate__backInRight"></i>
                            :
                            <i className="fa-solid fa-pen-to-square fs-5 me-2 animate__animated animate__backInRight"></i>
                    }
                    <h5 className="mt-2 me-2">{selectedTemplate.id === ID_NOT_DEFINED ? "Crear Plantilla" : "Editar Plantilla"}</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className='col mb-3'>
                                <label className='fw-bold'>Nombre<b><i className="text-red">*</i></b></label>
                                <input
                                    type="text"
                                    className="form-control green-input"
                                    {...register('name', {
                                        required: 'Nombre es requerido',
                                        minLength: 3,
                                        maxLength: 50,
                                    })}
                                    placeholder="Digita el nombre"
                                />
                                {errors.name && <div className="fs-7 text-red text-end">{errors.name.message}</div>}
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className='fw-bold'>Asunto<b><i className="text-red">*</i></b></label>
                            <input
                                type="text"
                                className="form-control green-input"
                                {...register('subject', {
                                    required: 'Asunto es requerido',
                                    minLength: 3,
                                    maxLength: 50,
                                })}
                                placeholder="Digita el asunto"
                            />
                            {errors.subject && <div className="fs-7 text-red text-end">{errors.subject.message}</div>}
                        </div>
                        <div className='mb-3'>
                            <label className='fw-bold'>Contenido<b><i className="text-red">*</i></b></label>
                            <textarea
                                className="form-control custom-textarea green-input mt-1"
                                {...register('content', {
                                    required: 'Contenido es requerido',
                                    minLength: 3,
                                    maxLength: 1000,
                                })}
                                placeholder="Digita el contenido"
                            />
                            {errors.content && <div className="fs-7 text-red text-end">{errors.content.message}</div>}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                        <i className="fa-solid fa-file-invoice me-2"></i>
                        {
                            selectedTemplate.id === ID_NOT_DEFINED ?
                                "Agregar"
                                :
                                "Actualizar"
                        }
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}