import { useSelector } from "react-redux";

import { ItemListExpedient } from "./ItemListExpedient";

export const ListExpedient = ({roundedTable = false}) => {
    const { listFilter } = useSelector(state => state.expedientSlice);

    return (
        <>
            <div className="order">
                {
                    listFilter.length === 0 ?
                        <div className="alert alert-warning text-center mt-2" role="alert">
                            No hay expedientes que mostrar
                        </div>
                        :
                        <div className={`divxscroll clip-hide ${roundedTable ? "rounded-3": ""}`}>
                            <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFilter.length > 0 && "table-striped"}`}>
                                <thead>
                                    <tr className="font-lato">
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>Nombre</th>
                                        <th>Tipo de proceso</th>
                                        <th>Fecha inicio</th>
                                        <th>Fecha fin</th>
                                        <th>Acciones</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFilter.map((item) => <ItemListExpedient
                                            key={item.id}
                                            expedient={item}
                                        />)
                                    }
                                </tbody>
                            </table>
                        </div>
                }
            </div>
        </>
    );
}
