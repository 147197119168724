import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ModalLabelGlobal from "./components/ModalLabelGlobal";
import IconButton from "../../app/components/IconButton";
import { SearchBar } from "../../app/components/SearchBar";

import GeneralService from "../../services/GeneralService";
import confirmDelete from "../../utils/confirmDelete";
import { cleanData, deleteFromLabels, reducerFormSearch, setList, setSelected } from "../../reducers/configuration/reducerLabelGlobal";
import { MessageError } from "../../utils/message";
import { toast } from "../../utils/toast";
import { CustomPagination } from "../../app/components/CustomPagination";

const TabLabelGlobal = () => {
    const { listFilter } = useSelector(state => state.labelGlobalSlice);
    const [show, setShow] = useState(false);
    const [pagination, setPagination] = useState({ total_pages: 0, page: 0, page_size: 10 });
    const dispatch = useDispatch();
    const service = new GeneralService("label-global");

    const loadLabels = async () => {
        const res = await service.getList(0, pagination);
        const { labels = [], total_pages = 0 } = res;
        dispatch(setList(labels));
        setPagination({ ...pagination, total_pages });
    }

    const deleteLabel = async (id) => {
        const res = await service.delete(id);
        if (res.is_ok) {
            toast(dispatch, res.message);
            dispatch(deleteFromLabels(id));
            return;
        }
        MessageError(res.message, service.status);
    }

    const onSearch = (e) => {
        dispatch(reducerFormSearch(e.target.value));
    }

    useEffect(() => {
        loadLabels();
    }, [pagination.page, pagination.page_size]);

    return (
        <div className="animate__animated animate__fadeIn">

            <div className="row center-vertical">
                <div className="col-12 col-lg-4">
                    <h5 className="font-lato text-green">
                        <i className="fa-solid fa-tag me-2"></i>
                        Etiquetas globales
                    </h5>
                </div>

                <div className="col-10 col-lg-5">
                    <SearchBar onChange={onSearch} />
                </div>

                <div className="col-2 col-lg-3">
                    <button title="Crear etiqueta" className="btn btn-success d-block rounded-pill ms-auto" type="button"
                        onClick={() => { dispatch(cleanData()); setShow(true); }}>
                        <i className="fa-solid fa-plus text-white" />
                        <span className='d-none d-md-inline-block ms-2'>Crear</span>
                    </button>
                </div>
            </div>

            <div className='row mt-4'>
                <div className="col-12">

                    {
                        listFilter.length === 0 ?
                            <div className="alert alert-warning text-center mt-2" role="alert">
                                No hay etiquetas que mostrar
                            </div>
                            :
                            <div className="divxscroll d-block rounded-3 clip-hide">
                                <table className="table table-responsive table-header-custom animate__animated animate__fadeIn">
                                    <thead>
                                        <tr className="font-lato">
                                            <th></th>
                                            <th>#</th>
                                            <th>Nombre</th>
                                            <th>Descripción</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listFilter.map((item, i) => (
                                                <tr key={item.id} className="font-rubik">
                                                    <td></td>
                                                    <td style={{ width: "40px" }}>{i + 1}.</td>
                                                    <td>
                                                        <i className="fa-solid fa-tags text-green me-1"></i>
                                                        {item.name}
                                                    </td>
                                                    <td className="fw-normal">{item.description}</td>
                                                    <td>
                                                        <IconButton
                                                            icon="fa-solid fa-pen-to-square text-green"
                                                            title="Editar etiqueta"
                                                            onClick={() => {
                                                                dispatch(setSelected(item));
                                                                setShow(true);
                                                            }}
                                                        />
                                                        <IconButton
                                                            icon="fa-solid fa-trash-can text-red"
                                                            title="Eliminar etiqueta"
                                                            onClick={async () => await confirmDelete(() => deleteLabel(item.id))}
                                                        />
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                    }

                </div>
            </div>

            <CustomPagination
                pagination={pagination}
                setPagination={setPagination}
            />

            <ModalLabelGlobal show={show} setShow={setShow} />

        </div>
    );
}

export default TabLabelGlobal;