import { useRef } from "react";
import { useSelector } from "react-redux";

import Selecctores from "./selecctores";
import PeopleClusters from "./peopleClusters";
import BreadcrumbCustom from '../../../../app/components/BreadcrumCustom';
import AccordionCategory from './AccordionCategory';

import usePeople from "../hooks/usePeople";
import { ID_NOT_DEFINED } from "../../../../constant/constant";
import { TabsElatedToPerson } from "./TabsElatedToPerson";

const People = () => {
    const { id, saveDataPeople } = usePeople();
    const { selectedTypepeople, listCategories } = useSelector(state => state.typepeopleSlice);    
    const refForm = useRef();

    return (
        <div className="container-fluid max-height-overflow-y">
            <div className="row">
                <form ref={refForm} onSubmit={saveDataPeople}>
                    <BreadcrumbCustom
                        title={id === 0 ? 'Crear Persona' : 'Editar Persona'}
                        routes={[
                            { name: "Personas", link: "/people/list" },
                        ]}
                        activeRoute={id === 0 ? 'Crear Persona' : 'Editar Persona'}
                    />
                    <Selecctores id={id} />
                    <div className="mt-3" />
                    <PeopleClusters id={id} refForm={refForm} />
                    <hr className="mt-4" />
                    {
                        listCategories?.length !== 0 &&
                        <>
                            <h4 className="text-center mb-1 mt-3">
                                <strong>Categorías</strong>
                            </h4>
                            {
                                listCategories.map((category) => <AccordionCategory
                                    key={category.id}
                                    category={category}
                                    id={id}
                                    refForm={refForm}
                                />)
                            }
                            <hr className="mt-4" />
                        </>
                    }
                    {
                        Object.keys(selectedTypepeople).length !== 0 && selectedTypepeople.clusters !== undefined &&
                        <div className="row">
                            <div className="col">
                                <button type="submit"
                                    className="btn btn-success d-block rounded-3 mx-auto my-3 px-3 py-2">
                                    <i className="fa-solid fa-person-circle-plus me-1"></i>
                                    {id === ID_NOT_DEFINED ? "Crear" : "Actualizar"}
                                </button>
                            </div>
                        </div>
                    }
                </form >
            </div>
            {
                id !== ID_NOT_DEFINED &&
                <div className="row mt-5">
                    <TabsElatedToPerson peopleId={id} />
                </div>
            }
        </div >
    );

}
export default People;