import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';

import { Color } from '../../../../app/components/Color';

import GeneralService from '../../../../services/GeneralService';
import { insertFromMovement, reducerForm, updateFromMovement } from '../../../../reducers/expedient/reducerMovements';
import { ID_NOT_DEFINED } from '../../../../constant/constant';
import { MessageError, toast } from '../../../../utils';
import { loadOptionColor, loadOptionDocument, loadOptionUser } from '../../parameterization/utils/utils';
import { reactSelectStyles } from '../styles/reactSelectStyles';

export const ModalMovement = ({ show, setShow }) => {
    const { selectedExpedient } = useSelector(state => state.expedientSlice);
    const { selectedMovement } = useSelector(state => state.movementsSlice);
    const { list } = useSelector(state => state.colorsSlice);
    const { list: listUsers } = useSelector(state => state.userSlice);
    const { listDocuments } = useSelector(state => state.documentsAutoSlice);
    const { nick_name } = useSelector(state => state.loginSlice.profile);
    const dispatch = useDispatch();

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const handleSelectedColor = (selected, actionMeta) => {
        dispatch(reducerForm({
            key: "colors_id",
            value: selected.value,
        }));
    }

    const handleSelectedUser = (selected, actionMeta) => {
        dispatch(reducerForm({
            key: "user_id",
            value: selected.value,
        }));
    }

    const handleSelectedDocument = (selected, actionMeta) => {
        dispatch(reducerForm({
            key: "documents_auto_id",
            value: selected.value,
        }));
    }

    const loadResponsible = () => {
        if (selectedMovement.id !== ID_NOT_DEFINED) return;
        dispatch(reducerForm({ key: "user_id", value: listUsers.find(user => user.nick_name === nick_name)?.id }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const service = new GeneralService("movement");

        const data = {
            ...selectedMovement,
            expedients_id: selectedExpedient.id,
            document: undefined,
            color: undefined,
        }

        if (selectedMovement.id === ID_NOT_DEFINED) {
            const res = await service.post(data);
            if (res.is_ok) {
                setShow(false);
                toast(dispatch, res.message);
                dispatch(insertFromMovement(res.movement));
                return;
            }
            MessageError(res.message, service.status);
            return;
        }

        const res = await service.update(data);
        if (res.is_ok) {
            setShow(false);
            toast(dispatch, res.message);
            dispatch(updateFromMovement(selectedMovement));
            return;
        }
        MessageError(res.message, service.status);
    }

    useEffect(() => {
        loadResponsible();
    }, [nick_name, listUsers, selectedMovement.id, show]);

    return (
        <Modal show={show} onHide={() => setShow(false)} size='xl'>
            <form onSubmit={handleSubmit} autoCorrect="on">
                <Modal.Header className='bg-light-green pe-4' closeButton>
                    {
                        selectedMovement.id === ID_NOT_DEFINED ?
                            <i className="fa-solid fa-circle-plus animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                            :
                            <i className="fa-solid fa-pen-to-square animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                    }
                    <h5 className="mt-2 ms-2">
                        {
                            selectedMovement.id === ID_NOT_DEFINED ?
                                "Crear Movimiento"
                                :
                                "Editar Movimiento"
                        }
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className='col-7'>
                                <label className="font-rubik">Nombre<b><i className="text-red">*</i></b></label>
                                <input
                                    type="text"
                                    className="form-control green-input"
                                    name="name"
                                    value={selectedMovement.name}
                                    maxLength={120}
                                    minLength={3}
                                    placeholder="Digita el nombre"
                                    autoComplete="off"
                                    autoCapitalize="words"
                                    onChange={setData}
                                    required
                                />
                            </div>
                            <div className="col-5">
                                <label className="font-rubik">Fecha de ejecución<b><i className="text-red">*</i></b></label>
                                <input
                                    type="datetime-local"
                                    className="form-control green-input"
                                    name="wait_time_days"
                                    value={selectedMovement.wait_time_days}
                                    onChange={setData}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className='col mb-3'>
                                <label className="font-rubik">Descripción</label>
                                <textarea
                                    className="form-control custom-textarea mt-1 green-input"
                                    name="description"
                                    value={selectedMovement.description}
                                    maxLength={300}
                                    placeholder="Ingrese una descripción"
                                    onChange={setData}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <label className="font-rubik">Color<b><i className="text-red">*</i></b></label>
                                <Select
                                    options={list.map(itemColor => {
                                        return {
                                            value: itemColor.id,
                                            label: <div className="select-label">
                                                <span>{itemColor.status}</span>
                                                <Color color={itemColor.color} />
                                            </div>
                                        }
                                    })}
                                    value={loadOptionColor(list, selectedMovement)}
                                    onChange={handleSelectedColor}
                                    styles={reactSelectStyles}
                                    isSearchable={false}
                                    required
                                />
                            </div>
                            <div className="col">
                                <label className="font-rubik">Responsable</label>
                                <Select
                                    options={[
                                        {
                                            value: undefined,
                                            label: "Ninguno",                                            
                                        },
                                        ...listUsers.map(itemUser => ({
                                            value: itemUser.id,
                                            label: itemUser.name + " " + itemUser.surname,
                                        }))
                                    ]}
                                    value={loadOptionUser(listUsers, selectedMovement)}
                                    onChange={handleSelectedUser}
                                    styles={reactSelectStyles}
                                    isSearchable={true}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-6">
                                <label className="font-rubik">Tipo de documento</label>
                                <Select
                                    options={[
                                        {
                                            value: undefined,
                                            label: "Ninguno",
                                        },
                                        ...listDocuments.map(itemDocument => ({
                                            value: itemDocument.id,
                                            label: itemDocument.name,
                                        }))
                                    ]}
                                    value={loadOptionDocument(listDocuments, selectedMovement)}
                                    onChange={handleSelectedDocument}
                                    styles={reactSelectStyles}
                                    isSearchable={true}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                        <i className="fa-solid fa-paper-plane me-2"></i>
                        {
                            selectedMovement.id === ID_NOT_DEFINED ?
                                "Agregar"
                                :
                                "Actualizar"
                        }
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}