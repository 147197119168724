import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from 'query-string';

import BreadcrumbCustom from "../../../app/components/BreadcrumCustom";
import { SearchBar } from "../../../app/components/SearchBar";
import { ListTypeMovement } from "./components/ListTypeMovement";
import { ModalMovement } from "./components/ModalMovement";

import GeneralService from "../../../services/GeneralService";
import { cleanData, reducerFormSearch } from "../../../reducers/expedient/reducerTypeProcessMovement";
import { setListParametrizeDocuments } from "../../../reducers/documents/reducerGenerateDocument";
import { setList } from "../../../reducers/configuration/reducerColors";

export const TypeMovementsView = () => {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const { search } = useLocation();
    const { typeProcess } = queryString.parse(search);

    const loadTypeDocuments = async () => {
        const service = new GeneralService("documents-auto");
        const res = await service.getList(1000);
        const { documents = [] } = res;
        dispatch(setListParametrizeDocuments({ value: documents }));
    }

    const onSearch = (e) => {
        dispatch(reducerFormSearch(e.target.value));
    }

    const loadColors = async () => {
        const service = new GeneralService("colors");
        const res = await service.getList({ page_size: 1000 });
        const { colors = [] } = res;
        dispatch(setList(colors));
    }
    
    useEffect(() => {
        loadColors();
        loadTypeDocuments();
    }, []);

    return (
        <>
            <div className="container-fluid max-height-overflow-y">
                <BreadcrumbCustom
                    title="Movimientos"
                    routes={[
                        { name: "Tipos de movimientos", link: "/type-process/list" },
                    ]}
                    activeRoute="Listado"
                />

                <div className='row'>
                    <div className="col-10 col-lg-6">
                        <SearchBar onChange={onSearch} />
                    </div>
                    <div className='col-2 col-lg-6'>
                        <button title="Crear nuevo movimiento"
                            className="btn btn-success rounded-pill" type="button"
                            onClick={() => { dispatch(cleanData()); setShow(true); }}
                        >
                            <i className="fa-solid fa-plus text-white" />
                            <span className='d-none d-md-inline-block ms-2'>Crear</span>
                        </button>
                    </div>
                </div>

                <div className="table-data" >
                    <ListTypeMovement setShow={setShow} typeProcess={typeProcess} />
                </div>
            </div>

            <ModalMovement show={show} setShow={setShow} typeProcess={typeProcess} />
        </>
    )
}