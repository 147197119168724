import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { COLLECTION_PEOPLE } from '../../../constant/constant';

export const FormCreateActor = ({ firstRow, onAddMapping }) => {
    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
    const { column, typePeople, label } = watch();
    const { listActorsSelected } = useSelector(state => state.documentsAutoSlice);

    const onSubmit = () => {
        const data = {
            collection: COLLECTION_PEOPLE,
            excel_column: parseInt(column),
            label_global_id: parseInt(label),
            type_peoples_id: parseInt(typePeople),
        }

        onAddMapping(data);
        reset();
    }

    return (
        <form className='row' onSubmit={handleSubmit(onSubmit)}>
            <div className="col-12 col-lg-3">
                <label className="fw-bold">Columna Excel<b><i className="text-red">*</i></b></label>
                <select
                    className="form-select dark-green-input mb-1"
                    {...register('column', { required: 'Campo de columna requerido' })}
                >
                    <option value="">Seleccione...</option>
                    {
                        firstRow?.map((cell, index) => (<option key={index} value={index}>{cell}</option>))
                    }
                </select>
                {errors.column && <div className="fs-7 text-red text-end">{errors.column.message}</div>}
            </div>
            <div className="col-12 col-lg-3">
                <label className="fw-bold">Tipo de persona<b><i className="text-red">*</i></b></label>
                <select
                    className="form-select dark-green-input mb-1"
                    {...register('typePeople', { required: 'Tipo de persona es requerido' })}
                >
                    <option value="">Seleccione...</option>
                    {
                        listActorsSelected?.map(actor => (<option key={actor.id} value={actor.type_peoples_id}>{actor.type_peoples?.name}</option>))
                    }
                </select>
            </div>
            <div className="col-12 col-lg-3">
                <label className="fw-bold">Etiqueta<b><i className="text-red">*</i></b></label>
                <select
                    className="form-select dark-green-input mb-1"
                    {...register('label', { required: 'La etiqueta es requerida' })}
                >
                    <option value="">Seleccione...</option>
                    {
                        listActorsSelected.find(actor => actor.type_peoples_id === parseInt(typePeople))?.
                            labels.map(label => (<option key={label.id} value={label.id}>{label.name}</option>))
                    }
                </select>
            </div>
            <div className="col-12 col-lg-3 d-flex align-items-end">
                <button type="submit" title="Añadir mapeo" className="btn btn-success mt-3 d-block mx-auto">
                    <i className="fa-solid fa-plus text-white me-2" />
                    Añadir
                </button>
            </div>
        </form>
    )
}