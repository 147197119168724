import { ConfigMappings } from './ConfigMappings'

export const Step2 = ({state}) => {
    return (
        <>
            <p className="mt-2">
                <span className="text-green fw-bold">2. </span>
                A continuación, selecciona la columna de Excel y el campo específico que deseas relacionar en el documento.
            </p>
            <ConfigMappings
                firstRow={state.fileData[0]}
                collection={state.collection}
                typePeople={state.typePeople}
                typeDocument={state.typeDocument}
            />
        </>
    )
}
