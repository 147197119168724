import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import IconButton from "../../../app/components/IconButton";
import { SearchBar } from "../../../app/components/SearchBar";
import { ModalTemplateEmail } from "./ModalTemplateEmail";

import GeneralService from "../../../services/GeneralService";
import { HIDE, SHOW } from "../../../constant/constant";
import { reducerFormSearch, setSelected, cleanData, setList, deleteFromTemplate } from "../../../reducers/configuration/reducerTemplateEmail";
import { toast, MessageError } from "../../../utils";
import confirmDelete from "../../../utils/confirmDelete";

export const TemplateEmail = () => {
    const { listFilter } = useSelector(state => state.templateEmailSlice);
    const [show, setShow] = useState(HIDE);
    const dispatch = useDispatch();
    const service = new GeneralService("template-email");

    const onSearch = (e) => {
        dispatch(reducerFormSearch(e.target.value));
    }

    const loadTemplates = async () => {
        const res = await service.getList(0);
        const { content = [] } = res;
        dispatch(setList(content));
    }

    const deleteTemplate = async (id) => {
        const res = await service.delete(id);
        if (res.is_ok) {
            toast(dispatch, res.message);
            dispatch(deleteFromTemplate(id));
            return;
        }
        MessageError(res.message, service.status);
    }

    useEffect(() => {
        loadTemplates();
    }, []);

    return (
        <>
            <div className="row center-vertical">
                <div className="col-12 col-lg-4">
                    <h5 className="font-lato text-green">
                        <i className="fa-solid fa-file-invoice me-2"></i>
                        Plantillas de correo
                    </h5>
                </div>

                <div className="col-10 col-lg-5">
                    <SearchBar onChange={onSearch} />
                </div>

                <div className="col-2 col-lg-3">
                    <button title="Crear color" className="btn btn-success d-block rounded-pill ms-auto" type="button"
                        onClick={() => { dispatch(cleanData()); setShow(SHOW); }}>
                        <i className="fa-solid fa-plus text-white" />
                        <span className='d-none d-md-inline-block ms-2'>Crear</span>
                    </button>
                </div>
            </div>
            <div className="divxscroll d-block rounded-3 clip-hide mt-3">
                <table className="table table-responsive table-header-custom animate__animated animate__fadeIn">
                    <thead>
                        <tr className="font-lato">
                            <th></th>
                            <th>#</th>
                            <th>Nombre</th>
                            <th>Asunto</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listFilter.length === 0
                                ?
                                <tr>
                                    <td colSpan={5} className="text-center">No hay plantillas que mostrar</td>
                                </tr>
                                :
                                listFilter.map((item, i) => (
                                    <tr
                                        key={item.id}
                                        className="font-rubik change-background-animation"
                                        onDoubleClick={() => { dispatch(setSelected(item)); setShow(SHOW); }}
                                    >
                                        <td></td>
                                        <td style={{ width: "40px" }}>{i + 1}.</td>
                                        <td>{item.name}</td>
                                        <td>{item.subject}</td>
                                        <td>
                                            <IconButton
                                                icon="fa-solid fa-pen-to-square text-green"
                                                title="Editar plantilla"
                                                onClick={() => {
                                                    dispatch(setSelected(item));
                                                    setShow(SHOW);
                                                }}
                                            />
                                            <IconButton
                                                icon="fa-solid fa-trash-can text-red"
                                                title="Eliminar plantilla"
                                                onClick={async () => await confirmDelete(() => deleteTemplate(item.id))}
                                            />
                                        </td>
                                    </tr>
                                ))
                        }
                    </tbody>
                </table>
            </div>

            <ModalTemplateEmail show={show} setShow={setShow} />
        </>
    )
}
