import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import confirmDelete from '../../../utils/confirmDelete';
import { MessageError, MessageSuccess } from '../../../utils/message';

import { deleteForm, setSelectedForm, updateForm } from '../../../reducers/forms/reducerFormSlice';
import IconButton from '../../../app/components/IconButton';
import GeneralService from '../../../services/GeneralService';
import CardSwitch from '../../../app/components/CardSwitch';
import { toast } from '../../../utils/toast';
import { TOAST_INFO } from '../../../constant/constant';

const ListForm = ({ setShow }) => {
    const { listFilter } = useSelector(state => state.formSlice);
    const dispatch = useDispatch();
    const history = useHistory();

    const deleteItem = async (id) => {
        const service = new GeneralService("form");
        const res = await service.delete(id);
        if (res.is_ok) {
            dispatch(deleteForm({ value: id }));
            MessageSuccess(res.message);
            return;
        }
        MessageError(res.message, service.status);
    }

    const publicForm = async (item) => {
        const data = { ...item, is_public: !item.is_public };
        const service = new GeneralService("form");
        const res = await service.update(data);
        if (res.is_ok) {
            dispatch(updateForm({ value: data }));
            if(data.is_public) toast(dispatch, "El formulario ha sido publicado");
            else toast(dispatch, "Formulario ocultado", TOAST_INFO);
            
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <>
            <div className="order">
                {
                    listFilter.length === 0 ?
                        <div className="alert alert-warning text-center mt-2" role="alert">
                            No hay formularios que mostrar
                        </div>
                        :
                        <div className="divxscroll d-block rounded-3 clip-hide">
                            <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFilter.length > 0 && "table-striped"}`}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>#</th>
                                        <th>Nombre </th>
                                        <th>Descripción</th>
                                        <th>Publicar</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFilter.map((item, i) => (
                                            <tr key={item.id}>
                                                <td></td>
                                                <td style={{width: "40px"}}>{i + 1}.</td>
                                                <td style={{ maxWidth: "200px" }}>{item.name}</td>
                                                <td style={{ maxWidth: "400px" }}>{item.description}</td>
                                                <td>
                                                    <CardSwitch
                                                        checked={item.is_public}
                                                        className="ms-3"
                                                        icon=""
                                                        name=""
                                                        onChange={() => publicForm(item)}
                                                    />
                                                </td>

                                                <td>
                                                    <IconButton
                                                        icon="fa-solid fa-pen-to-square text-green"
                                                        title="Editar formulario"
                                                        onClick={() => {
                                                            dispatch(setSelectedForm({ value: item }));
                                                            setShow(true);
                                                        }}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-trash-can text-red"
                                                        title="Eliminar formulario"
                                                        onClick={async () => await confirmDelete(() => deleteItem(item.id))}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-layer-group text-purple"
                                                        title="Ver grupos"
                                                        onClick={() => {
                                                            dispatch(setSelectedForm({ value: item }));
                                                            history.push("/form-parameterization/cluster");
                                                        }}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-users text-orange"
                                                        title="Ver actores"
                                                        onClick={() => {
                                                            dispatch(setSelectedForm({ value: item }));
                                                            history.push("/form-parameterization/actors");
                                                        }}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-gear text-green"
                                                        title="Configura diálogos y texto del formulario"
                                                        onClick={() => history.push("/form-parameterization/text/" + item.id)}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                }
            </div>
        </>
    )
}

export default ListForm;