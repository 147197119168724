import { encodeFileBase64, MessageError } from "../../../utils";
import { useSelector } from "react-redux";
import { COLLECTION_DOCUMENTS, COLLECTION_PEOPLE } from "../../../constant/constant";
import { readExcelFile } from "../utils/utils";

export const Step1 = ({ state, setState }) => {
    const { list } = useSelector(state => state.typepeopleSlice);
    const { list: listTemplates } = useSelector(state => state.documentsAutoSlice);

    const handleFileUpload = async (e) => {
        try {
            const file = e.target.files[0];
            if (!file) return;

            const { fileData, fileName } = await readExcelFile(file);
            const document = await encodeFileBase64(file);
            setState({ ...state, fileData, fileName, document });
        } catch (error) {
            console.error("Error leyendo el archivo:", error);
            MessageError("Error procesando el archivo");
        }
    }

    return (
        <>
            <p>
                <span className="text-green fw-bold">1. </span>
                Primero, carga el documento, selecciona la colección deseada y define la plantilla o el tipo de persona correspondiente.
            </p>
            <div className="row">
                <div className="col-12 col-lg-5">
                    <label className="fw-bold">Cargue documento<i className="text-red">*</i></label>
                    <input
                        type="file"
                        className="form-control dark-green-input mb-1"
                        accept=".xlsx,.xls"
                        onChange={handleFileUpload}
                        required={true}
                    />
                </div>
                <div className="col-12 col-lg-3">
                    <label className="fw-bold">Colección<i className="text-red">*</i></label>
                    <select
                        className="form-select dark-green-input mb-1"
                        name="collection"
                        value={state.collection}
                        onChange={(e) => setState({ ...state, collection: e.target.value })}
                        required
                    >
                        <option value="">Seleccione...</option>
                        <option value={COLLECTION_PEOPLE}>Personas</option>
                        <option value={COLLECTION_DOCUMENTS}>Documentos</option>
                    </select>
                </div>
                <div className="col-12 col-lg-4">
                    {
                        state.collection === COLLECTION_PEOPLE
                            ?
                            <>
                                <label>Tipo de persona<b><i className="text-red">*</i></b></label>
                                <select
                                    className="form-select dark-green-input mb-1"
                                    name="type_people_id"
                                    value={state.typePeople}
                                    onChange={(e) => setState({ ...state, typePeople: e.target.value })}
                                >
                                    <option value="">Seleccione...</option>
                                    {
                                        list.map(typeP => (<option value={typeP.id} key={typeP.id}> {typeP.name}</option>))
                                    }
                                </select>
                            </>
                            :
                            <>
                                <label className="fw-bold">Plantilla<i className="text-red">*</i></label>
                                <select
                                    className="form-select dark-green-input mb-1"
                                    name="documents_auto_id"
                                    value={state.typeDocument}
                                    onChange={(e) => setState({ ...state, typeDocument: e.target.value })}
                                >
                                    <option value="">Seleccione...</option>
                                    {
                                        listTemplates.map(item => (<option value={item.id} key={item.id}>{item.name}</option>))
                                    }
                                </select>
                            </>
                    }
                </div>
            </div>
        </>
    )
}
