import { useDispatch } from "react-redux";

import { valueInputPeople } from "../../utils/functions";
import { setSelectedPerson } from "../../../../reducers/documents/reducerGenerateDocument";
import TagOutlined from "../../../../assets/tagOutlined.svg";

const CardConfigActor = ({ person, active, canEdit, refForm }) => {
    const dispatch = useDispatch();

    const handleSelectActor = () => {
        if (!active) {
            if (refForm.current.checkValidity()) dispatch(setSelectedPerson({ value: person }));
            else refForm.current.reportValidity();
        }
    }

    return (
        <div className={`card-actor ${active ? "active" : ""}`} onClick={handleSelectActor}>
            <div className="description">
                <span className="fw-bold">Actor:</span> {canEdit ? valueInputPeople(person.people) : person.name}
            </div>
            <div className="label fs-8">
                <img className="me-1" src={TagOutlined} /> {person.labels?.name}
            </div>
        </div>
    )
}

export default CardConfigActor;