import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import IconButton from "../../app/components/IconButton";
import { SearchBar } from "../../app/components/SearchBar";

import GeneralService from "../../services/GeneralService";
import confirmDelete from "../../utils/confirmDelete";
import { MessageError } from "../../utils/message";
import { toast } from "../../utils/toast";
import { cleanData, deleteFromColors, orderColor, reducerFormSearch, setList, setSelected } from "../../reducers/configuration/reducerColors";
import ModalColors from "./components/ModalColors";
import { Color } from "../../app/components/Color";
import { CustomPagination } from "../../app/components/CustomPagination";
import { showAnimateOrderItem } from "../../utils";
import { useAnimateBackground } from "../../app/hooks/useAnimateBackground";

const TabColors = () => {
    const { listFilter } = useSelector(state => state.colorsSlice);
    const [show, setShow] = useState(false);
    const [pagination, setPagination] = useState({ total_pages: 0, page: 0, page_size: 10 });
    const dispatch = useDispatch();
    const [showEffect, setShowEffect] = useAnimateBackground();
    const service = new GeneralService("colors");

    const loadColors = async () => {
        const res = await service.getList(0, pagination);
        const { colors = [], total_pages = 0 } = res;
        dispatch(setList(colors));
        setPagination({ ...pagination, total_pages });
    }

    const deleteColor = async (id) => {
        const res = await service.delete(id);
        if (res.is_ok) {
            toast(dispatch, res.message);
            dispatch(deleteFromColors(id));
            return;
        }
        MessageError(res.message, service.status);
    }

    const onSearch = (e) => {
        dispatch(reducerFormSearch(e.target.value));
    }

    const handleMove = async (id, order) => {
        const service = new GeneralService("colors/order");
        const res = await service.post({ id, order });
        if (res.is_ok) {
            dispatch(orderColor({ value: { id, order } }));
            setShowEffect({ show: true, id });
            return;
        }
        MessageError(res.message, service.status);
    }

    useEffect(() => {
        loadColors();
    }, [pagination.page, pagination.page_size]);

    return (
        <div className="animate__animated animate__fadeIn">

            <div className="row center-vertical">
                <div className="col-12 col-lg-4">
                    <h5 className="font-lato text-green">
                        <i className="fa-solid fa-palette me-2"></i>
                        Colores
                    </h5>
                </div>

                <div className="col-10 col-lg-5">
                    <SearchBar onChange={onSearch} />
                </div>

                <div className="col-2 col-lg-3">
                    <button title="Crear color" className="btn btn-success d-block rounded-pill ms-auto" type="button"
                        onClick={() => { dispatch(cleanData()); setShow(true); }}>
                        <i className="fa-solid fa-plus text-white" />
                        <span className='d-none d-md-inline-block ms-2'>Crear</span>
                    </button>
                </div>
            </div>

            <div className='row mt-3'>
                <div className="col-12" style={{ height: "25rem", overflowY: "auto" }}>

                    {
                        listFilter.length === 0 ?
                            <div className="alert alert-warning text-center mt-2" role="alert">
                                No hay colores que mostrar
                            </div>
                            :
                            <div className="divxscroll d-block rounded-3 clip-hide">
                                <table className="table table-responsive table-header-custom animate__animated animate__fadeIn">
                                    <thead>
                                        <tr className="font-lato">
                                            <th></th>
                                            <th>#</th>
                                            <th>Color</th>
                                            <th>Estatus</th>
                                            <th>Descripción</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listFilter.map((item, i) => (
                                                <tr
                                                    key={item.id}
                                                    className={`font-rubik change-background-animation ${showAnimateOrderItem(item, showEffect)}`}
                                                    onDoubleClick={() => { dispatch(setSelected(item)); setShow(true); }}
                                                >
                                                    <td></td>
                                                    <td style={{width: "40px"}}>{i + 1}.</td>
                                                    <td><Color color={item.color} size={30} /></td>
                                                    <td>{item.status}</td>
                                                    <td>{item.description}</td>
                                                    <td>
                                                        <IconButton
                                                            icon="fa-solid fa-pen-to-square text-green"
                                                            title="Editar etiqueta"
                                                            onClick={() => {
                                                                dispatch(setSelected(item));
                                                                setShow(true);
                                                            }}
                                                        />
                                                        <IconButton
                                                            icon="fa-solid fa-trash-can text-red"
                                                            title="Eliminar etiqueta"
                                                            onClick={async () => await confirmDelete(() => deleteColor(item.id))}
                                                        />
                                                        <div className="move-parameterization">
                                                            <i className="fa-solid fa-circle-arrow-up text-green me-2" role='button' onClick={() => handleMove(item.id, (i - 1))}></i>
                                                            <i className="fa-solid fa-circle-arrow-down text-purple" role='button' onClick={() => handleMove(item.id, (i + 1))}></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                    }

                </div>
            </div>

            <CustomPagination
                pagination={pagination}
                setPagination={setPagination}
            />

            <ModalColors show={show} setShow={setShow} />

        </div >
    );
}

export default TabColors;