

const CardSwitch = ({ icon, title, name, checked, onChange, className = "", labelClassName: labelClassName = "", disabled, tooltip }) => {
    return (
        <div className={`d-flex form-check form-switch divswicth form-check-reverse justify-content-between ${className}`}>
            <label className={`form-check-label ${labelClassName}`} htmlFor={`switch-${name}`}>
                {title}
            </label>
            <input
                className="form-check-input"
                name={name}
                title={tooltip}
                type="checkbox"
                role="switch"
                id={`switch-${name}`}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
        </div>
    )
}

export default CardSwitch;