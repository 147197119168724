import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import ListQuestions from './ListQuestions';

const ModalCodesQuestions = ({ show, setShow, insertCode }) => {

  const handleClose = () => {
    setShow(false);
  }

  return (
    <Modal show={show} onHide={handleClose} centered size='xl'>
      <Modal.Header className='bg-light-green pe-4' closeButton>
        <div className='container'>
          <div className='row'>

            <div className='col-1'>
              <i className='fa-solid fa-circle-question fs-4 mt-2'></i>
            </div>
            <div className='col mt-2'>
              <h5>Códigos de preguntas</h5>
            </div>

          </div>
        </div>

      </Modal.Header>
      <Modal.Body>
        <div className='container-fluid'>
          <ListQuestions showActions={false} onTapCode={(obj, type)=>insertCode("@"+obj.code_questions?.code)} aditionalIDDOM="show" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleClose}>
          Listo
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalCodesQuestions;