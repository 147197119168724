import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

import { setCheckCodes } from '../../../reducers/forms/reducerCreateProcessForm';

const ModalError = ({ show, setShow, errors }) => {
    const dispatch = useDispatch();

    const viewDocument = () => {
        dispatch(setCheckCodes({ value: false }));
        setShow(false);
    }

    return (
        <Modal show={show} onHide={viewDocument} centered size='lg'>
            <Modal.Header className='bg-light-green pe-4' closeButton>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <h5 className='text-center'>⚠️ ¡No tienes algunos datos! ⚠️</h5>
                        </div>
                    </div>
                </div>

            </Modal.Header>
            <Modal.Body>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col'>
                            <p>Te presentamos una lista de errores de datos que no fueron encontrados y el código que sustituye a cada uno para que completes la información manualmente.</p>
                            {
                                errors?.map((error, i) => <p key={i}>
                                    <b>{i + 1}. <i>{error.name}: </i></b> @{error.code}
                                </p>)
                            }
                            <br />

                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='abs-center'>
                <Button variant="success" onClick={viewDocument}>
                    Continuar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalError;