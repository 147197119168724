import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { MessageError, MessageSuccess } from '../../../../../utils/message';
import BreadcrumbCustom from '../../../../../app/components/BreadcrumCustom';

import { reducerForm, addParameterization, setDataGetById, nullLengths, setListParameterization } from '../../../../../reducers/forms/reducerParameterizationActorForm';
import { FIELD_FILE, FIELD_SELECT, FIELD_TABLE, ID_NOT_DEFINED } from '../../../../../constant/constant';
import { setSelectedActor } from '../../../../../reducers/forms/reducerActorForm';
import { setSelectedForm } from '../../../../../reducers/forms/reducerFormSlice';
import SelectControl from '../../../../../app/components/SelectControl';
import GeneralService from '../../../../../services/GeneralService';
import CardSwitch from '../../../../../app/components/CardSwitch';

const FormParameterizationActorField = () => {
    const { list, selectedForm } = useSelector(state => state.formSlice);
    const { selectedActor } = useSelector(state => state.actorFormSlice);
    const { parameterization } = useSelector(state => state.parameterizationActorFormSlice);
    const dispatch = useDispatch();
    const history = useHistory();
    let { id = 0 } = useParams();
    const { search } = useLocation();
    const { form = 0, actor = 0 } = queryString.parse(search);

    const loadData = async (id) => {
        dispatch(setSelectedForm({ value: list.find(i => i.id === parseInt(form)) }));

        const service = new GeneralService("actor-form/" + id);
        const res = await service.getRequest();
        if (res.name) {
            dispatch(setSelectedActor({ value: res }));
            const { parameterizations = [] } = res;
            dispatch(setListParameterization({ value: parameterizations }));
        } else {
            history.push("/form-parameterization/actors");
        }
    }

    useEffect(() => {
        loadData(actor);
    }, [actor, form, list]);

    const field = parseInt(parameterization.type_fields_id);
    const required = field === 3 || field === 4 || field === 5 || field === 6 || field === 7 || field === 9 || field === 10;

    useEffect(() => {
        if (required) {
            dispatch(nullLengths());
        }
    }, [required]);

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const getParameterization = async (id) => {
        if (id !== ID_NOT_DEFINED) {
            const service = new GeneralService("parameterization-actor/" + id);
            const res = await service.getRequest();
            if (Object.keys(res).length > 0) {
                dispatch(setDataGetById({ value: res }));
                return;
            }
            MessageError("La parametrización no existe");
            history.push("/form-parameterization/actors");
            return;
        }
        if (form === ID_NOT_DEFINED || actor === ID_NOT_DEFINED) history.push("/form-parameterization/actors");
    }

    useEffect(() => {
        getParameterization(id);
        return () => {
            dispatch(setDataGetById({ value: undefined }));
        }
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const service = new GeneralService("parameterization-actor");

        const data = {
            id: parameterization.id,
            form_id: parseInt(form) ?? 0,
            actor_form_id: parseInt(actor) ?? 0,
            name: parameterization.name,
            type_fields_id: field ?? 0,
            length_min: parseInt(parameterization.length_min) ?? 0,
            length_max: parseInt(parameterization.length_max) ?? 0,
            required: parameterization.required.toString() === "true",
            value_select: parameterization.value_select,
            alert_required: parameterization.alert_required.toString() === "true",
            type_contact: parameterization.type_contact,
            apply_format: parameterization.apply_format.toString() === "true",
        };
        if (data.type_fields_id === FIELD_FILE) data.type_files_id = parseInt(parameterization.type_files_id) ?? 0;

        if (field === FIELD_SELECT && parameterization.value_select.split("*").length < 3) {
            MessageError("El campo de selección no tiene más de una opción");
            return;
        }
        if (field !== FIELD_SELECT && field !== FIELD_TABLE) {
            delete data["value_select"];
        }

        if (id === ID_NOT_DEFINED) {
            const res = await service.post(data);
            if (res.is_ok) {
                MessageSuccess(res.message);
                dispatch(addParameterization({ value: res.parameterizarion }));
                history.goBack();
                return;
            }
            MessageError(res.message, service.status);
        }
        const res = await service.update(data);
        if (res.is_ok) {
            MessageSuccess(res.message);
            history.goBack();
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <div className='mx-4'>
            <BreadcrumbCustom
                title={id === ID_NOT_DEFINED ? "Crear Campo" : "Editar Campo"}
                routes={[
                    { name: "Tipos de formulario", link: "/form-parameterization/list" },
                    { name: selectedForm.name ? selectedForm.name : "Seleccione tipo de formulario", link: "/form-parameterization/list" },
                    { name: selectedActor.name ? selectedActor.name : "Actor no seleccionado", link: `/form-parameterization/actors/fields?form=${selectedForm.id}&actor=${selectedActor.id}` },
                ]}
                activeRoute={id === ID_NOT_DEFINED ? "Crear Campo" : "Editar Campo"}
            />

            <div className="col-12 table-data mt-3">
                <form onSubmit={handleSubmit}>
                    <div className="card-body">
                        <div className="row mb-2">
                            <div className='col-md-6'>
                                <label>Nombre del campo<b><i className="text-red">*</i></b></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    placeholder="Ingrese un nombre"
                                    value={parameterization.name}
                                    minLength={2}
                                    maxLength={100}
                                    autoComplete="off"
                                    onChange={setData}
                                    required
                                />
                            </div>

                            <div className={`${(field === 1 || field === 8) ? "col-md-2" : "col-md-6"}`}>
                                <label>Tipo de campo<b><i className="text-red">*</i></b></label>
                                <select
                                    className="form-select"
                                    name="type_fields_id"
                                    value={parameterization.type_fields_id}
                                    onChange={setData}
                                    required
                                >
                                    <option value="">
                                        Seleccione
                                    </option>
                                    <option value="8">Texto</option>
                                    <option value="1">Número</option>
                                    <option value="2">Decimal</option>
                                    <option value="7">Correo Electrónico</option>
                                    <option value="9">Campo de Selección</option>
                                    <option value="10">Tabla</option>
                                    <option value="3">Fecha</option>
                                    <option value="4">Hora</option>
                                    <option value="5">Fecha-Hora</option>
                                    <option value="6">Archivo</option>
                                </select>
                            </div>

                            {
                                (field === 1 || field === 8) &&
                                <div className="col-md-4 pt-1">
                                    <CardSwitch
                                        checked={parameterization.apply_format}
                                        icon="fa-solid fa-list-ol"
                                        name="apply_format"
                                        title={field === 1 ? "¿Aplicar formato de miles?" : "¿Aplicar formato de mayúsculas?"}
                                        className='mt-4'
                                        onChange={(e) => {
                                            dispatch(reducerForm({
                                                key: e.target.name,
                                                value: e.target.checked,
                                            }));
                                        }}
                                    />
                                </div>
                            }

                            <div className='col-md-3 mt-2'>
                                <label>Longitud mínima{!required && <b><i className="text-red">*</i></b>}</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="length_min"
                                    placeholder="Ingrese longitud mínima"
                                    value={parseInt(parameterization.length_min) === 0 ? "" : parameterization.length_min}
                                    min={0}
                                    onChange={setData}
                                    disabled={required}
                                    required={!required}
                                />
                            </div>
                            <div className='col-md-3 mt-2'>
                                <label>Longitud máxima{!required && <b><i className="text-red">*</i></b>}</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="length_max"
                                    placeholder="Ingrese longitud mínima"
                                    value={parseInt(parameterization.length_max) === 0 ? "" : parameterization.length_max}
                                    min={parameterization.length_min}
                                    onChange={setData}
                                    disabled={required}
                                    required={!required}
                                />
                            </div>

                            <div className='col-md-6 mt-2'>
                                <div className="row">
                                    <div className="col">
                                        <label>Requerido<b><i className="text-red">*</i></b></label>
                                        <select
                                            className="form-select"
                                            name="required"
                                            value={parameterization.required}
                                            onChange={setData}
                                            required
                                        >
                                            <option value="">
                                                Seleccione
                                            </option>
                                            <option value={true}>Si</option>
                                            <option value={false}>No</option>
                                        </select>
                                    </div>
                                </div>
                                {
                                    parameterization.required.toString() === "false" &&
                                    <div className="row mt-1">
                                        <div className="form-check form-switch ms-3">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                role="switch"
                                                id="flexSwitchCheckChecked"
                                                checked={parameterization.alert_required}
                                                onChange={(e) => {
                                                    dispatch(reducerForm({
                                                        key: "alert_required",
                                                        value: !parameterization.alert_required,
                                                    }));
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">¿Alertar en caso de campo vacío?</label>
                                        </div>
                                    </div>
                                }
                            </div>

                            {
                                field === FIELD_FILE &&
                                (
                                    <div className="col-md-6 mt-2">
                                        <label>¿Que tipo de archivo es?<b><i className="text-red">*</i></b></label>
                                        <select
                                            className="form-select"
                                            name="type_files_id"
                                            value={parameterization.type_files_id}
                                            onChange={setData}
                                        >
                                            <option value="">
                                                Seleccione
                                            </option>
                                            <option value="1">Documento</option>
                                            <option value="2">Comprimido</option>
                                            <option value="3">Imagen</option>
                                            <option value="4">Video</option>
                                            <option value="5">Audio</option>
                                        </select>
                                    </div>
                                )
                            }

                            {
                                (field === FIELD_SELECT || field === FIELD_TABLE) &&
                                <div className="col-6 mt-2">
                                    <SelectControl
                                        fields={parameterization.value_select}
                                        isFieldSelect={field === FIELD_SELECT}
                                        onChange={(value) => {
                                            dispatch(reducerForm({
                                                key: "value_select",
                                                value,
                                            }));
                                        }}
                                    />
                                </div>
                            }

                            <div className="col-md-6 mt-2">
                                <label>
                                    ¿Es un campo de contacto?<b><i className="text-red">*</i></b>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Popover id={`popover-positioned`}>
                                                <Popover.Header as="h3" className='bg-light-purple text-center'><span className='text-purple'>Campo de contacto</span></Popover.Header>
                                                <Popover.Body>
                                                    <p>Ayúdanos a identificar qué campos son de contacto, recuerda que los campos de tipo contacto son importantes para automatizar la gestión de datos de las personas que firmarán el documento.

                                                        <br /><br />Por ejemplo si marcas este campo como <strong>Campo tipo nombre</strong> Zapsign sabrá que lo puede utilizar como campo para el nombre del firmante.
                                                    </p>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i className="fa-solid fa-circle-question ms-2 text-light-purple" role='button'></i>
                                    </OverlayTrigger>
                                </label>
                                <select
                                    className="form-select"
                                    name="type_contact"
                                    value={parameterization.type_contact}
                                    onChange={setData}
                                >
                                    <option value="NO_CONTACT">NO es un campo de contacto</option>
                                    <option value="CONTACT_NAME">Campo tipo nombre</option>
                                    <option value="CONTACT_EMAIL">Campo tipo email</option>
                                    <option value="CONTACT_PHONE">Campo tipo teléfono</option>
                                </select>
                            </div>

                        </div>

                        <div className='row'>
                            <div className='col mt-2'>
                                <button className='btn btn-success d-block ms-auto' type='submit'>
                                    <i className="fa-solid fa-paper-plane me-2" style={{ fontSize: '16px' }}></i>
                                    {id === 0 ? "Crear" : "Editar"} campo
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default FormParameterizationActorField;
