import { useState } from 'react';

import BreadcrumbCustom from '../../../app/components/BreadcrumCustom';
import ListTypepeople from './listTypepeople';
import ModalTypePeople from './ModalTypePeople';
import { useDispatch } from 'react-redux';
import { cleanData, searchTypepeople } from '../../../reducers/typePeople/reducerTypepeople';
import { SearchBar } from '../../../app/components/SearchBar';

const Typepeople = () => {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    const onSearch = (e) => {
        dispatch(searchTypepeople({ value: e.target.value }));
    }

    return (
        <div className="container-fluid max-height-overflow-y">
            <div className="row">
                <div className="col">
                    <BreadcrumbCustom
                        title="Tipos de persona"
                        activeRoute="/ Listar tipos de persona"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-10 col-lg-6">
                    <SearchBar onChange={onSearch} />
                </div>
                <div className="col-2 col-lg-6">
                    <button title="Crear nuevo tipo de persona" className="btn btn-success rounded-pill" type="button" onClick={() => { setShow(true); dispatch(cleanData()); }}>
                        <i className="fa-solid fa-plus text-white" />
                        <span className='d-none d-md-inline-block ms-2'>Crear</span>
                    </button>
                </div>
            </div>

            <div className="table-data" >
                <ListTypepeople setShow={setShow} />
            </div>

            <ModalTypePeople show={show} setShow={setShow} />
        </div>
    )
}
export default Typepeople;
