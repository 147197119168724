import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentEditorContainerComponent, Editor, Selection, Toolbar, WordExport } from '@syncfusion/ej2-react-documenteditor';

import SidebarCodes from './Sidebar';
import ModalCodesQuestions from './ModalCodesQuestions';
import ModalAutomateParameterization from '../../../GptAutomation/components/ModalAutomateParameterization';
import ModalChatParameterization from '../../../GptAutomation/components/ModalChatParameterization';

import { MessageError, MessageVerifyCodes } from '../../../../utils/message';
import { isURL } from '../../../../utils/isUrl';
import { fixInlineFormatting, validateExistingCodes } from '../../utils/functions';
import SidebarCodeInformation from './SidebarCodeInformation';
import { toast } from '../../../../utils/toast';
import { hideLoading, showLoading } from '../../../../reducers/main/loadingReducer';
import GeneralService from '../../../../services/GeneralService';
import { ACTION_ADD_TO_END, ACTION_REPLACE_CONTENT } from '../../../GptAutomation/constant/constant';
import { PROMPT_DOCUMENTS } from '../../../GptAutomation/prompts/prompts';
import { extractAllCodesDocument } from '../../utils/exportFunctions';
import { getBlob, ref } from 'firebase/storage';
import { FirebaseStorage } from '../../../../firebase/config/firebase-config';
import { blobToBase64, createlinkDownloadBlob } from '../../../../utils';
import DocumentEditorService from '../../../../services/DocumentEditorService';
import { HTTP_OK } from '../../../../constant/constant';

DocumentEditorContainerComponent.Inject(Toolbar, Selection, Editor, WordExport);

const TabText = ({ change }) => {
    const { list: listTypePeople } = useSelector(state => state.typepeopleSlice);
    const { list: listGlobal } = useSelector(state => state.globalCodesSlice);
    const { dataDocument, codesQuestions, codesGlobal, codesActors, listQuestions, listActorsSelected } = useSelector(state => state.documentsAutoSlice);
    const [show, setShow] = useState(false);
    const [showChat, setShowChat] = useState({ modal: 1, show: false });
    const container = useRef(null);
    let hostUrl = "https://services.syncfusion.com/react/production/api/documenteditor/";
    const dispatch = useDispatch();

    const insertCode = (code) => {
        if (container.current) {
            container.current.documentEditor.editor.insertText(`${code} `);
        }
        toast(dispatch, `Se insertó ${code}`);
    }

    const verifyCodes = async () => {
        const template = container.current.documentEditor.serialize();
        const { sfdtData, errors } = await validateExistingCodes(template, codesGlobal, codesActors, codesQuestions);
        container.current.documentEditor.open(sfdtData);
        
        if (errors > 0) return await MessageVerifyCodes(parametrizeDocument);
        await parametrizeDocument();
    }
    
    const parametrizeDocument = async () => {
        const sfdt = container.current.documentEditor.serialize();
        const content = JSON.parse(sfdt);
        const cleanedSfdt = fixInlineFormatting(content);
        const cleanedSfdtSerialized = JSON.stringify(cleanedSfdt)

        container.current.documentEditor.open(cleanedSfdtSerialized);

        container.current.documentEditor.saveAsBlob('Docx').then(async (blobFile) => {
            const base64 = await blobToBase64(blobFile);
            const service = new GeneralService("documents-auto/parameterization");

            dispatch(showLoading());
            const res = await service.update({ id: dataDocument.id, url_template_html: base64 });
            dispatch(hideLoading());
            if (res.is_ok) {
                toast(dispatch, res.message);
                return;
            }
            MessageError(res.message, service.status);
        });
    }

    const handleChangeContentChat = (content = "", action) => {
        switch (action) {
            case ACTION_ADD_TO_END:
                container.current.documentEditor.editor.insertText(content);
                return;
            case ACTION_REPLACE_CONTENT:
                return;
            default:
                console.log("opcion no disponible");
        }
    }

    const textCodes = () => {
        return extractAllCodesDocument(dataDocument.name, listActorsSelected, listTypePeople, listQuestions, listGlobal);
    }

    const rendereComplete = () => {
        window.onbeforeunload = () => {
            return "¡No olvides guardar los cambios del editor!";
        };
        container.current.documentEditor.pageOutline = "#E0E0E0";
        container.current.documentEditor.acceptTab = true;
        container.current.documentEditor.resize();
    };

    const loadDocument = async () => {
        if (!isURL(dataDocument.url_template_html)) return;
        dispatch(showLoading());
        const blob = await getBlob(ref(FirebaseStorage, dataDocument.url_template_html));
        const serviceDocument = new DocumentEditorService();
        const sfdtData = await serviceDocument.convertDocxToSfdt(blob);
        if (serviceDocument.status === HTTP_OK) {
            container.current.documentEditor.open(sfdtData);
        }
        dispatch(hideLoading());
    }

    const downloadDocx = () => {
        const sfdt = container.current.documentEditor.serialize();
        const content = JSON.parse(sfdt);
        const cleanedSfdt = fixInlineFormatting(content);
        const cleanedSfdtSerialized = JSON.stringify(cleanedSfdt, null, 2)

        container.current.documentEditor.open(cleanedSfdtSerialized);
        container.current.documentEditor.saveAsBlob('Docx').then((blob) => {
            createlinkDownloadBlob(blob, dataDocument.name);
        });
    }

    useEffect(() => {
        rendereComplete();
    }, []);

    useEffect(() => {
        if (container.current) {
            loadDocument();
        }
    }, [dataDocument.url_template_html]);

    useEffect(() => {
        if (container.current) {
            container.current.documentEditor.resize();
        }
    }, [change]);

    return (
        <>
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col">
                        <button title="Guardar contenido" className="btn btn-success rounded-pill px-2 py-1 mx-1" type="button" onClick={verifyCodes}>
                            <i className="fa-solid fa-floppy-disk text-white"></i>
                        </button>
                        <button title="Descargar documento parametrizado" className="btn btn-success rounded-pill px-2 py-1 mx-1" type="button" onClick={downloadDocx}>
                            <i className="fa-solid fa-arrow-down text-white"></i>
                        </button>
                    </div>
                    <div className="col">
                        <button title="Visualizar códigos" className="btn btn-success rounded-pill px-2 py-1 mx-1 float-end" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">
                            <i className="fa-solid fa-angles-left text-white"></i>
                        </button>
                        <button title="Códigos de preguntas" className="btn btn-success rounded-pill px-2 py-1 mx-1 float-end" type="button"
                            onClick={() => setShow(true)}
                        >
                            <i className="fa-solid fa-sitemap text-white"></i>
                        </button>
                        <button title="Visualizar códigos" className="btn btn-success rounded-pill px-2 py-1 mx-1 float-end" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebarCodeInformation" aria-controls="sidebarCodeInformation">
                            <i className="fa-solid fa-circle-question text-white"></i>
                        </button>
                        <button title="Parametrización automática" className="btn btn-success rounded-pill px-2 py-1 mx-1 float-end" type="button"
                            onClick={() => setShowChat(s => ({ ...s, show: true }))}
                        >
                            <i className="fa-solid fa-robot text-white"></i>
                        </button>
                    </div>

                </div>
                <div className="row">
                    <div className="col">
                        <DocumentEditorContainerComponent
                            id="container"
                            ref={container}
                            style={{ display: "block" }}
                            height={"660px"}
                            serviceUrl={hostUrl}
                            enableToolbar={true}
                            locale="en-US"
                            documentEditorSettings={{
                                preserveWhitespace: false,
                                enableOptimizedTextExtraction: false,                                
                            }}
                        />
                    </div>
                </div>
            </div>

            <ModalCodesQuestions show={show} setShow={setShow} insertCode={insertCode} />
            <SidebarCodes insertCode={insertCode} />
            <SidebarCodeInformation />

            <ModalAutomateParameterization
                show={showChat.modal === 1 && showChat.show}
                setShow={setShowChat}
                textCodes={textCodes()}
                prompt={PROMPT_DOCUMENTS}
            />
            <ModalChatParameterization
                show={showChat.modal === 2 && showChat.show}
                setShow={setShowChat}
                onChangeContent={handleChangeContentChat}
            />
        </>
    );
}

export default TabText;