import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import confirmDelete from '../../../utils/confirmDelete';
import { MessageError, MessageSuccess } from '../../../utils/message';

import { deleteTypepeople, setSelectedTypepeople } from '../../../reducers/typePeople/reducerTypepeople';
import IconButton from '../../../app/components/IconButton';
import GeneralService from '../../../services/GeneralService';
import { HTTP_OK } from '../../../constant/constant';

const ListTypepeople = ({ setShow }) => {
    const { listFilter } = useSelector(state => state.typepeopleSlice);
    const dispatch = useDispatch();
    const history = useHistory();

    const deletePeople = async (id) => {
        const service = new GeneralService("typepeople");
        const res = await service.delete(id);
        if (service.status === HTTP_OK) {
            dispatch(deleteTypepeople({ value: id }));
            MessageSuccess(res.message);
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <>
            <div className="order">
                {
                    listFilter.length === 0 ?
                        <div className="alert alert-warning text-center mt-2" role="alert">
                            No hay tipos de persona que mostrar
                        </div>
                        :
                        <div className="divxscroll d-block rounded-3 clip-hide">
                            <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFilter.length > 0 && "table-striped"}`}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>#</th>
                                        <th>Nombre </th>
                                        <th>Descripción</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFilter.map((item, i) => (
                                            <tr key={item.id}>
                                                <td></td>
                                                <td style={{ width: "40px" }}>{i + 1}.</td>
                                                <td style={{ maxWidth: "200px" }}>{item.name}</td>
                                                <td style={{ maxWidth: "400px" }}>{item.description}</td>
                                                <td>
                                                    <IconButton
                                                        icon="fa-solid fa-pen-to-square text-green"
                                                        title="Editar tipo de persona"
                                                        onClick={() => {
                                                            dispatch(setSelectedTypepeople({ value: item }));
                                                            setShow(true);
                                                        }}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-layer-group text-green"
                                                        title="Ver grupos"
                                                        onClick={() => {
                                                            dispatch(setSelectedTypepeople({ value: item }));
                                                            history.push("/typepeople/groups");
                                                        }}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-shapes text-green"
                                                        title="Ver categorías"
                                                        onClick={() => {
                                                            dispatch(setSelectedTypepeople({ value: item }));
                                                            history.push("/typepeople/categories");
                                                        }}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-trash-can text-red"
                                                        title="Eliminar tipo de persona"
                                                        onClick={async () => await confirmDelete(() => deletePeople(item.id))}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                }
            </div>
        </>
    )
}

export default ListTypepeople;