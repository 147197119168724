import { FirebaseApp, FirebaseStorage } from "./config/firebase-config";
import { getStorage, ref, getBytes, uploadBytes, deleteObject, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { isURL } from "../utils/isUrl";

// Downloads the content from firebase
export const downloadFile = async (url = "") => {
    try {
        if (!isURL(url)) return "";

        const obj = await getBytes(ref(FirebaseStorage, url));
        let enc = new TextDecoder("utf-8");
        let arr = new Uint8Array(obj);

        return enc.decode(arr);
    } catch (error) {
        console.log(error);
        return "";
    }
}

// Upload files to firebase
export const uploadFilesFirebase = async (files, path) => {
    try {
        const listUpload = [];
        for (let i = 0; i < files.length; i++) {
            const identifier = uuidv4();
            const storageRef = ref(FirebaseStorage, `${path}/${identifier}.${files[i].name.split(".").pop()}`);
            const uploaded = await uploadBytes(storageRef, files[i]);
            uploaded.metadata.name = files[i].name;
            listUpload.push(uploaded);
        }
        return { is_ok: true, listUpload };
    } catch (e) {
        debugger
        return { is_ok: false, message: "Error al subir archivo(s) a firebase" };
    }
}

// Delete file from firebase
export const deleteFilesFirebase = async (path) => {
    try {
        await deleteObject(ref(FirebaseStorage, path));
        return true;
    } catch (error) {
        console.log('Ha ocurriudo un error al eliminar el archivo', error);
        return false;
    }
}

export const downloadImageAsBase64 = async (url = "") => {
    if (!isURL(url)) return "";

    try {
        const downloadUrl = await getDownloadURL(ref(FirebaseStorage, url));
        const response = await fetch(downloadUrl);
        const blob = await response.blob();

        return await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        console.error("Error downloading image:", error);
        return "";
    }
}

export const downloadFileAsBytes = async (url = "") => {
    if (!isURL(url)) return null;

    try {
        const downloadUrl = await getDownloadURL(ref(FirebaseStorage, url));
        const response = await fetch(downloadUrl);
        const blob = await response.blob();

        return await blob.arrayBuffer();
    } catch (error) {
        console.error("Error downloading file:", error);
        return null;
    }
}