import { Modal } from "react-bootstrap";

import { ColorPicker } from "./index";

import GeneralService from "../../../services/GeneralService";
import { useDispatch, useSelector } from "react-redux";
import { MessageError, toast } from "../../../utils";
import { TOAST_SUCCESS, HIDE } from "../../../constant/constant";
import {
  setListLabels,
  setLabel,
  setCleanLabel,

} from "../../../reducers/calendar/reducerCalendar";

export const ModalLabel = ({ show, setShow }) => {
  const { label } = useSelector(state => state.calendarslice);
  const dispatch = useDispatch();

  //Labelsubmit
  const labelSubmit = async (e) => {
    e.preventDefault();
    const service = new GeneralService("labels");
    const res = await service.post(label);
    if (res.is_ok) {
      toast(dispatch, res.message, TOAST_SUCCESS, 5000);
      getLabels();
      dispatch(setCleanLabel());
      setShow(HIDE);
      return;
    }
    MessageError(res.message);
  };

  const getLabels = async () => {
    const service = new GeneralService("labels");
    const object = await service.getList(1000);
    const labels = object.labels;
    dispatch(setListLabels({ value: labels }));
  };

  //agregar etiqueta
  const SetdataLabels = (e) => {
    const data = {
      key: e.target.name,
      value: e.target.value,
    };
    dispatch(setLabel(data));
  }

  return (
    <Modal show={show} onHide={() => setShow(false)} size='md'>
      <Modal.Header className='bg-light-green pe-4' closeButton>
        <i className="fa-solid fa-circle-plus animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
        <h5 className="mt-2 ms-2">Crear etiqueta</h5>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={labelSubmit}>
          <div className="divinput">
            <i className="fa-solid fa-file-pen text-green"></i>
            <input
              className="form-control green-input"
              type="text"
              placeholder="Nombre de la categoría"
              name="name"
              value={label.name}
              onChange={(event) => SetdataLabels(event)}
              required
              autoComplete="off"
            />
          </div>

          <h5 className='font-lato mt-3'>Elige un color</h5>
          <ColorPicker className="color-pick" />

          <div className="divbotones">
            <button type="submit" title="Agregar"
              className="btn btn-success"
            >
              <i className="fa-solid fa-paper-plane text-white me-2" />
              Guardar
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
