export const CHECK_CODES = true;
export const NO_CHECK_CODES = false;
export const ID_NOT_DEFINED = 0;
export const HTTP_OK = 200;
export const HTTP_CREATED = 201;
export const HTTP_NO_CONTENT = 204;
export const BAD_REQUEST = 400;
export const HTTP_FORBIDDEN = 403;
export const IS_A_CATEGORY = "1";
export const QUESTION_CLOSED = "QUESTION_CLOSED";
export const FINISH = true;
export const NO_FINISH = 0;
export const SENT = true;
export const SHOW = true;
export const HIDE = false;
export const ALL_ACTORS = "TODOS";
export const TYPE_ANSWER = "ANSWER";
export const TYPE_QUESTION = "QUESTION";

// Fields
export const FIELD_NUMBER = 1;
export const FIELD_DECIMAL = 2;
export const FIELD_DATE = 3;
export const FIELD_TIME = 4;
export const FIELD_DATETIME = 5;
export const FIELD_FILE = 6;
export const FIELD_EMAIL = 7;
export const FIELD_TEXT = 8;
export const FIELD_SELECT = 9;
export const FIELD_TABLE = 10;

// Toast
export const TOAST_SUCCESS = "toast-success";
export const TOAST_WARNING = "toast-warning";
export const TOAST_DANGER = "toast-danger";
export const TOAST_INFO = "toast-info";

// States signers
export const STATUS_SIGNED = "signed";
export const STATUS_NO_SIGNED = "new";

// Consumption
export const ALERT_TYPE = {
    STORAGE: {
        gradient: "gradient-orange",
        icon: "fa solid fa-file-zipper",
        name: "Almacenamiento",
    },
    EMAILS: {
        gradient: "gradient-blue",
        icon: "fa-solid fa-envelope",
        name: "Emails",
    },
    GENERATE_DOCUMENT: {
        gradient: "gradient-purple",
        icon: "fa-solid fa-file",
        name: "Documentos",
    },
    SIGN_DOCUMENT: {
        gradient: "gradient-green",
        icon: "fa-solid fa-signature",
        name: "Firmas",
    },
    REGISTER_USER: {
        gradient: "gradient-red",
        icon: "fa-solid fa-user",
        name: "Usuarios",
    },
    GPT_TOKENS: {
        gradient: "gradient-yellow",
        icon: "fa-solid fa-robot",
        name: "GPT tokens",
    },
}

export const MODEL_CASES = "CASES";
export const MODEL_TYPE_PEOPLE = "TYPE_PEOPLE";

export const USE_FOR_SEARCH = "USE_FOR_SEARCH";
export const USE_FOR_TABLE = "USE_FOR_TABLE";

export const MODULE_USERS = 1;
export const MODULE_BUSINESS = 2;
export const MODULE_NOTIFICATIONS = 3;
export const MODULE_TYPE_PEOPLE = 4;
export const MODULE_CASES = 5;
export const MODULE_CONFIGURATIONS = 6;
export const MODULE_DOCUMENTS_PARAMETERIZATION = 7;
export const MODULE_DOCUMENTS_GENERATION = 8;
export const MODULE_EXPEDIENTS = 9;
export const MODULE_VIRTUAL_NOTARY = 10;
export const MODULE_AUTOMATION_GPT = 11;
export const MODULE_ROLES = 12;

export const APP_MODULES = {
    [MODULE_USERS]: "Usuarios",
    [MODULE_BUSINESS]: "Empresas",
    [MODULE_NOTIFICATIONS]: "Notificaciones",
    [MODULE_TYPE_PEOPLE]: "Personas",
    [MODULE_CASES]: "Casos",
    [MODULE_CONFIGURATIONS]: "Configuraciones",
    [MODULE_DOCUMENTS_PARAMETERIZATION]: "Parametrización de Documentos",
    [MODULE_DOCUMENTS_GENERATION]: "Generación de Documentos",
    [MODULE_EXPEDIENTS]: "Expedientes",
    [MODULE_VIRTUAL_NOTARY]: "Notaría Virtual",
    [MODULE_AUTOMATION_GPT]: "Automatización GPT",
    [MODULE_ROLES]: "Roles",
};

export const ACTION_CAN_READ = "can_read";
export const ACTION_CAN_WRITE = "can_write";
export const ACTION_CAN_DELETE = "can_delete";

export const COLLECTION_PEOPLE = "PEOPLE";
export const COLLECTION_DOCUMENTS = "DOCUMENTS";

export const STORAGE_MANUAL_NOTIFICATION = "STORAGE_MANUAL_NOTIFICATION";
export const STORAGE_FILES = "STORAGE_FILES";