import { useState } from "react";

import BreadcrumbCustom from "../../../app/components/BreadcrumCustom";
import { CustomPagination } from "../../../app/components/CustomPagination";
import DynamicTable from "./DynamicTable";
import { SearchBarPeople } from "./SearchBarPeople";
import { SelectAndCreate } from "./SelectAndCreate";

const ListPeople = () => {
    const [pagination, setPagination] = useState({ total_pages: 0, page: 0, page_size: 10 });

    return (
        <div className="container-fluid max-height-overflow-y">
            <div className="row">
                <div className="col">
                    <BreadcrumbCustom
                        title="Personas"
                        routes={[
                            { name: "Personas", link: "/people/list" },
                        ]}
                        activeRoute="Listar personas"
                    />
                </div>
            </div>
            <div className='container-fluid'>
                <div className="table-data">
                    <div className='row'>
                        <SelectAndCreate />
                    </div>
                    <div className='row mt-3'>
                        <SearchBarPeople pagination={pagination} setPagination={setPagination} />
                    </div>
                    <div className='row mt-2'>
                        <DynamicTable actionName="Acciones" />
                    </div>
                    <CustomPagination
                        pagination={pagination}
                        setPagination={setPagination}
                    />
                </div>
            </div>
        </div>
    )
};

export default ListPeople;
