import { useResize } from "../hooks/useResize";
import "./styles/cardProcess.css";

const CardProcess = ({
  useIcon,
  icon,
  title,
  subtitle,
  iconColor,
  isActive,
  order,
  currentStep, 
  className = "",
  onClick = () => {},
  icon_style,
}) => {
  const isCompleted = order <= currentStep;

  return (
    <div
      title={subtitle}
      onClick={onClick}
      className={`col card-custom__config bg-white py-0 px-0 ${className}`}
    >
      <div className="containercard">
        <div className="inercard" style={{ marginRight: "10px" }}>
          <i
            className={`fa-solid me-2 ${icon} ${
              isCompleted ? "text-green" : iconColor
            } fs-4 mt-1`}
          ></i>

          <h9
            className={`mt-1 ms-1 ${
              isCompleted ? "text-green" : "text-gray"
            }`}
          >
            <b>{title}</b>
          </h9>
        </div>
        {useIcon && <i className="fa-solid fa-angle-right" />}
      </div>
    </div>
  );
};

export default CardProcess;
