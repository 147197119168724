import { createSlice } from "@reduxjs/toolkit";

export const templateEmailSlice = createSlice({
    name: "templateEmailSlice",
    initialState: {
        selectedTemplate: {
            id: 0,
            name: "",
            subject: "",
            content: "",
        },
        list: [],
        listFilter: [],
    },
    reducers: {
        setList: (state, { payload }) => {
            state.list = payload;
            state.listFilter = payload;
        },
        reducerForm: (state, { payload }) => {
            state.selectedTemplate[payload.key] = payload.value;
        },
        reducerFormSearch: (state, { payload }) => {
            if (payload === "") {
                state.listFilter = state.list;
                return;
            }
            const filtered = state.list.filter(e => e.status.toLowerCase().includes(payload.toLowerCase()))
            state.listFilter = filtered;
        },
        deleteFromTemplate: (state, { payload }) => {
            state.list = state.list.filter(e => e.id !== payload);
            state.listFilter = state.listFilter.filter(e => e.id !== payload);
        },
        updateFromTemplate: (state, action) => {
            const code = action.payload;

            state.list = state.list.map((e) => e.id === code.id ? code : e);
            state.listFilter = state.listFilter.map((e) => e.id === code.id ? code : e);
        },
        insertFromTemplate: (state, { payload }) => {
            state.list.push(payload);
            state.listFilter.push(payload);
        },
        setSelected: (state, action) => {
            state.selectedTemplate = action.payload;
        },
        cleanData: (state, action) => {
            state.selectedTemplate = {
                id: 0,
                name: "",
                subject: "",
                content: "",
            };
        },
    },
});

export const {
    setList,
    reducerForm,
    reducerFormSearch,
    deleteFromTemplate,
    updateFromTemplate,
    insertFromTemplate,
    setSelected,
    cleanData,
} = templateEmailSlice.actions;

export default templateEmailSlice.reducer;