import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import GeneralService from "../../../services/GeneralService";
import { setFormConfigEmail, saveConfigEmail } from "../../../reducers/customMail/reducerCustommail";
import { MessageError, toast } from "../../../utils";
import { TOAST_SUCCESS } from "../../../constant/constant";

export const CustomEmailConfiguration = () => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isConnectionTested, setIsConnectionTested] = useState(false);
    const dispatch = useDispatch();
    const { configEmail } = useSelector((state) => state.customMailSlice);

    const setData = (e) => {
        dispatch(
            setFormConfigEmail({
                key: e.target.name,
                value: e.target.value,
            })
        );
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = { ...configEmail, port: parseInt(configEmail.port) };
        const service = new GeneralService("custom-email");
        const res = await service.post(data);
        if (res.is_ok) {
            dispatch(saveConfigEmail(res.content));
            toast(dispatch, res.message, TOAST_SUCCESS, 5000)
            return;
        }
        MessageError(res.message);
    }

    const handleTestConnection = async () => {
        const service = new GeneralService("custom-email/verify");
        let data = { ...configEmail, port: parseInt(configEmail.port) };
        const res = await service.post(data);
        if (res.is_ok) {
            setShowConfirmation(true);
            setIsConnectionTested(true);
        } else {
            MessageError(res.message);
        }
    }

    useEffect(() => {
        if (showConfirmation) {
            const timer = setTimeout(() => {
                setShowConfirmation(false);
            }, 8000);

            return () => clearTimeout(timer);
        }
    }, [showConfirmation]);

    return (
        <>
            <h5 className="font-lato text-green">
                <i className="fa-solid fa-envelope me-2"></i>
                Correo personalizado
            </h5>
            <div>
                <p>
                    Configure los detalles del correo electrónico para enviar y recibir
                    notificaciones relacionadas con sus expedientes y movimientos.
                </p>

                <form className="px-0" onSubmit={handleSubmit}>
                    <div className="mt-2">
                        <label>Correo electrónico<b><i className="text-red">*</i></b></label>
                        <input
                            value={configEmail.email}
                            className="form-control green-input"
                            type="email"
                            placeholder="ejemplo@ejemplo.com"
                            name="email"
                            onChange={setData}
                        />
                    </div>

                    <div className="mt-2">
                        <label>Servidor SMTP<b><i className="text-red">*</i></b></label>
                        <input
                            value={configEmail.smtp}
                            type="text"
                            className="form-control green-input"
                            placeholder="Dirección IP"
                            name="smtp"
                            onChange={setData}
                        />
                    </div>

                    <div className="mt-2">
                        <label>Puerto<b><i className="text-red">*</i></b></label>
                        <input
                            value={configEmail.port}
                            type="number"
                            className="form-control green-input"
                            placeholder="1202"
                            name="port"
                            onChange={setData}
                        />
                    </div>

                    <div className="mt-2">
                        <label>Nombre de usuario<b><i className="text-red">*</i></b></label>
                        <input
                            value={configEmail.username}
                            type="text"
                            className="form-control green-input"
                            placeholder="jhondoe"
                            name="username"
                            onChange={setData}
                        />
                    </div>

                    <div className="mt-2">
                        <label>Contraseña<b><i className="text-red">*</i></b></label>
                        <input
                            value={configEmail.password}
                            type="password"
                            className="form-control green-input"
                            placeholder="********"
                            name="password"
                            onChange={setData}
                        />
                    </div>

                    <div className="mt-2">
                        <div className="mt-3 d-flex flex-row">
                            <button
                                type="submit"
                                className="btn btn-success d-block w-50 rounded-0"
                                disabled={!isConnectionTested}
                                style={{
                                    opacity: isConnectionTested ? 1 : 0.5,
                                    cursor: isConnectionTested ? "pointer" : "not-allowed",
                                }}
                            >
                                Guardar configuración
                            </button>
                            <button
                                type="button"
                                onClick={handleTestConnection}
                                className="btn btn-secondary text-center d-block w-50 rounded-0"
                            >
                                Probar conexión
                            </button>
                        </div>
                        {showConfirmation && (
                            <div
                                className="confirmation-message"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "15px",
                                    backgroundColor: "#d4edda",
                                    color: "#000000",
                                    borderRadius: "4px",
                                    height: "40px",
                                }}
                            >
                                <i
                                    className="fa-solid fa-check-circle"
                                    style={{ marginRight: "5px", color: "#155724" }}
                                />
                                Configuración probada con éxito
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </>
    )
}
