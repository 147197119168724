import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccordionActor from './AccordionActor';
import GeneralAccordion from '../../../../app/components/GeneralAccordion';
import IconButton from '../../../../app/components/IconButton';
import CardGlobalCode from './CardGlobalCode';

import GeneralService from '../../../../services/GeneralService';
import { setList } from '../../../../reducers/configuration/reducerGlobalCodes';
import { extractGlobalCodes } from '../../utils/functions';
import { setCodes } from '../../../../reducers/documents/reducerGenerateDocument';
import { extractAllCodesDocument } from '../../utils/exportFunctions';
import { exportTextPlain } from '../../../../utils/exportTextPlain';
import './style/sidebar.css';

const SidebarCodes = ({ insertCode }) => {
    const { list: listTypePeople } = useSelector(state => state.typepeopleSlice);
    const { list: listGlobal } = useSelector(state => state.globalCodesSlice);
    const { listQuestions, listActorsSelected, dataDocument } = useSelector(state => state.documentsAutoSlice);
    const dispatch = useDispatch();

    const loadCodes = async () => {
        const service = new GeneralService("global-codes");
        const res = await service.getList();
        const { global_codes = [] } = res;
        dispatch(setList({ value: global_codes }));
        dispatch(setCodes({ type: "CODES_GLOBAL", value: extractGlobalCodes(global_codes) }));
    }

    const handleExportCodes = () => {
        let text = extractAllCodesDocument(dataDocument.name, listActorsSelected, listTypePeople, listQuestions, listGlobal);
        exportTextPlain(dataDocument.name, text);
    }

    useEffect(() => {
        loadCodes();
    }, []);

    return (
        <>
            <div className="offcanvas offcanvas-end overflow-scroll" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                <div className="offcanvas-header bg-green">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-1">
                                <i className="fa-solid fa-clipboard-list fs-4 ms-2"></i>
                            </div>
                            <div className="col-8">
                                <h5 className="ms-4">Códigos</h5>
                            </div>
                            <div className="col-2">
                                <IconButton
                                    title="Exportar códigos"
                                    icon='fa-solid fa-file-export'
                                    onClick={handleExportCodes}
                                />
                            </div>
                            <button type="button" className="btn-close m-0" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                    </div>
                </div>

                <div className="offcanvas-body">

                    <div className="container-fluid w-100">
                        <div className="row m-0">
                            <GeneralAccordion
                                title="Globales"
                                icon="fa-earth-asia"
                                body={
                                    listGlobal.map(code => <CardGlobalCode
                                        key={code.id}
                                        code={code}
                                        onClick={() => insertCode("@" + code.code)}
                                    />)
                                }
                            />
                        </div>

                        <div className="row m-0 mt-2">
                            <GeneralAccordion
                                title="Actores"
                                icon="fa-users"
                                body={
                                    <>
                                        <p>Un actor puede tener una o más etiquetas. Puedes activar cualquiera de ellas, la que esté seleccionada se insertará junto con su código.</p>
                                        {
                                            listActorsSelected.map(e => {
                                                const typePeople = listTypePeople.find(t => t.id === e.type_peoples_id);
                                                return (<AccordionActor
                                                    actor={e}
                                                    typePeople={typePeople}
                                                    insertCode={insertCode}
                                                    key={e.id}
                                                />);
                                            })
                                        }
                                    </>
                                }
                            />
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default SidebarCodes;