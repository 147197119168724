// File to modify state of a optimized way
// migrations can be applied gradually

import { reducerFormGenerateDocument } from "./reducerGenerateDocument";
import GeneralService from "../../services/GeneralService";
import { MessageError } from "../../utils";
import { ID_NOT_DEFINED } from "../../constant/constant";

export const startModifyNeedsZapsign = (document_id, needs_zapsign) => {
    return async (dispatch) => {
        if (document_id !== ID_NOT_DEFINED) {
            const service = new GeneralService("generate-document/config-sending")
            const res = await service.post({ document_id, needs_zapsign })
            if (!res.is_ok) {
                MessageError(res.message);
                return;
            }
        }
        dispatch(reducerFormGenerateDocument({ key: "needs_zapsign", value: needs_zapsign }))
    }
}

export const startModifyUpdateTemplateEmail = (document_id, template_email_id) => {
    return async (dispatch) => {
        if (document_id !== ID_NOT_DEFINED) {
            const service = new GeneralService("generate-document/config-template")
            const res = await service.post({ document_id, template_email_id })
            if (!res.is_ok) {
                MessageError(res.message);
                return;
            }
        }
        dispatch(reducerFormGenerateDocument({ key: "template_email_id", value: template_email_id }))
    }
}