import { useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from 'query-string';

import BreadcrumbCustom from "../../app/components/BreadcrumCustom";
import CardConfiguration from "./CardConfiguration";
import TabFavoriteCodes from "./TabFavoriteCodes";
import TabCodesGlobal from "./TabCodesGlobal";
import TabLabelGlobal from "./TabLabelGlobal";
import TabZapsignConfiguration from "./TabZapsignConfiguration";
import TabColors from "./TabColors";
import { TabExpedients } from "./TabExpedients";

import "./style/configuration.css";
import TabEmail from "./TabEmail";
import { Accordion, Nav } from "react-bootstrap";
import { useResize } from "../../app/hooks/useResize";

const ConfigurationView = () => {
    const { search } = useLocation();
    const { tabIndex = "favorite-codes" } = queryString.parse(search);
    const [activeSection, setActiveSection] = useState(tabIndex);
    const { windowSize } = useResize();

    return (
        <div className="container-fluid max-height-overflow-y">
            <div className="row">
                <div className="col">
                    <BreadcrumbCustom
                        title="Configuraciones"
                        activeRoute="/ Configuraciones"
                    />
                </div>
            </div>

            {
                windowSize.width >= 995
                    ?
                    <div className="row">
                        <div className="col-lg-3">
                            <Nav className="nav-configuration">
                                <Nav.Item>
                                    <Nav.Link
                                        className={activeSection === "favorite-codes" ? "active" : ""}
                                        onClick={() => setActiveSection('favorite-codes')}
                                    >
                                        <i className="fa-solid fa-star me-2"></i>
                                        Códigos favoritos
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        className={activeSection === "global-codes" ? "active" : ""}
                                        onClick={() => setActiveSection('global-codes')}
                                    >
                                        <i className="fa-solid fa-earth-americas me-2"></i>
                                        Códigos globales
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        className={activeSection === "signature" ? "active" : ""}
                                        onClick={() => setActiveSection('signature')}
                                    >
                                        <i className="fa-solid fa-signature me-2"></i>
                                        Firma electrónica
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        className={activeSection === "labels" ? "active" : ""}
                                        onClick={() => setActiveSection('labels')}
                                    >
                                        <i className="fa-solid fa-user-tag me-2"></i>
                                        Etiquetas globales
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        className={activeSection === "colors" ? "active" : ""}
                                        onClick={() => setActiveSection('colors')}
                                    >
                                        <i className="fa-solid fa-swatchbook me-2"></i>
                                        Colores
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        className={activeSection === "expedients" ? "active" : ""}
                                        onClick={() => setActiveSection('expedients')}
                                    >
                                        <i className="fa-solid fa-folder me-2"></i>
                                        Expedientes
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        className={activeSection === "email" ? "active" : ""}
                                        onClick={() => setActiveSection('email')}
                                    >
                                        <i className="fa-solid fa-envelope-open-text me-2"></i>
                                        Servicio de correo
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="col-lg-8 me-1">
                            {activeSection === "favorite-codes" && <TabFavoriteCodes />}
                            {activeSection === "global-codes" && <TabCodesGlobal />}
                            {activeSection === "signature" && <TabZapsignConfiguration />}
                            {activeSection === "labels" && <TabLabelGlobal />}
                            {activeSection === "colors" && <TabColors />}
                            {activeSection === "expedients" && <TabExpedients />}
                            {activeSection === "email" && <TabEmail />}
                        </div>
                    </div>
                    :
                    <Accordion defaultActiveKey={[activeSection]} flush>
                        <Accordion.Item eventKey="favorite-codes">
                            <Accordion.Header>
                                <i className="fa-solid fa-star mx-2"></i>
                                Códigos favoritos
                            </Accordion.Header>
                            <Accordion.Body>
                                <TabFavoriteCodes />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="global-codes">
                            <Accordion.Header>
                                <i className="fa-solid fa-earth-americas mx-2"></i>
                                Códigos globales
                            </Accordion.Header>
                            <Accordion.Body>
                                <TabCodesGlobal />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="signature">
                            <Accordion.Header>
                                <i className="fa-solid fa-signature mx-2"></i>
                                Firma electrónica
                            </Accordion.Header>
                            <Accordion.Body>
                                <TabZapsignConfiguration />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="labels">
                            <Accordion.Header>
                                <i className="fa-solid fa-user-tag mx-2"></i>
                                Etiquetas globales
                            </Accordion.Header>
                            <Accordion.Body>
                                <TabLabelGlobal />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="colors">
                            <Accordion.Header>
                                <i className="fa-solid fa-swatchbook mx-2"></i>
                                Colores
                            </Accordion.Header>
                            <Accordion.Body>
                                <TabColors />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="expedients">
                            <Accordion.Header>
                                <i className="fa-solid fa-folder mx-2"></i>
                                Expedientes
                            </Accordion.Header>
                            <Accordion.Body>
                                <TabExpedients />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="email">
                            <Accordion.Header>
                                <i className="fa-solid fa-envelope-open-text mx-2"></i>
                                Servicio de correo
                            </Accordion.Header>
                            <Accordion.Body>
                                <TabEmail />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
            }
        </div>
    );
}

export default ConfigurationView;