import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MessageError, MessageSuccess } from '../../../../utils/message';
import service from './service/serviceCategory';
import { addNewObjectToList, cleanData, reducerForm, updateNewObjectAfterEdit } from '../../../../reducers/typePeople/reducerCategory';
import { ID_NOT_DEFINED } from '../../../../constant/constant';
import { setSelectedTypepeople } from '../../../../reducers/typePeople/reducerTypepeople';

const ModalCategoryPeople = ({ show, setShow }) => {
    const { list, selectedTypepeople } = useSelector(state => state.typepeopleSlice);
    const { selectedCategory } = useSelector(state => state.typepeopleCategorySlice);
    const dispatch = useDispatch();
    const history = useHistory();

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value
        }));

        if (e.target.name === "type_peoples_id") {
            const id = parseInt(e.target.value)
            dispatch(setSelectedTypepeople({ value: list.find(i => i.id === id) }));
            history.push(`?people=${id}`);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            id: selectedCategory?.id,
            name: selectedCategory?.name,
            description: selectedCategory?.description,
            type_peoples_id: selectedTypepeople?.id,
        };

        if (selectedCategory?.id === ID_NOT_DEFINED) {
            const res = await service.save(data);

            if (res.is_ok) {
                MessageSuccess(res.message)
                dispatch(addNewObjectToList({ value: res.category_people }));
                setShow(false);
                dispatch(cleanData());
                return;
            }
            MessageError(res.message, service.status);
            return;
        }
        const res = await service.update(data);
        if (res.is_ok) {
            MessageSuccess(res.message);
            dispatch(updateNewObjectAfterEdit({ value: data }));
            setShow(false);
            dispatch(cleanData());
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <form onSubmit={handleSubmit} autoCorrect="on">
                <Modal.Header className='bg-light-green pe-4' closeButton>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-1'>
                                {
                                    selectedCategory?.id === ID_NOT_DEFINED ?
                                        <i className="fa-solid fa-circle-plus mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                        :
                                        <i className="fa-solid fa-pen-to-square mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                }
                            </div>
                            <div className='col'>
                                <h5 className="mt-2">
                                    {
                                        selectedCategory?.id === ID_NOT_DEFINED ?
                                            "Crear Categoría"
                                            :
                                            "Editar Categoría"
                                    }
                                </h5>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="card-body">

                        <div className="row m-0 p-0">

                            <div className='mb-3'>
                                <label>Tipo de persona<b><i className="text-red">*</i></b></label>
                                <select
                                    className="form-select green-input"
                                    name="type_peoples_id"
                                    value={selectedTypepeople?.id}
                                    onChange={setData}
                                    required
                                >
                                    <option value="">
                                        Seleccione tipo de persona
                                    </option>
                                    {
                                        list.map(item => (<option value={item.id} key={item.id}>
                                            {item.name}
                                        </option>))
                                    }
                                </select>
                            </div>

                            <div className='mb-3'>
                                <label>Nombre<b><i className="text-red">*</i></b></label>
                                <input
                                    type="text"
                                    className="form-control green-input mt-1"
                                    name="name"
                                    value={selectedCategory?.name}
                                    maxLength={50}
                                    minLength={3}
                                    placeholder="Ingrese un nombre"
                                    autoComplete="off"
                                    autoCapitalize="words"
                                    onChange={setData}
                                    required
                                />
                            </div>

                            <div className='mb-3'>
                                <label>Descripción</label>
                                <textarea
                                    className="form-control green-input custom-textarea mt-1"
                                    name="description"
                                    value={selectedCategory?.description}
                                    maxLength={255}
                                    placeholder="Ingrese un nombre"
                                    onChange={setData}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                        <i className="fa-solid fa-paper-plane me-2" style={{ fontSize: '16px' }}></i>
                        {
                            selectedCategory?.id === ID_NOT_DEFINED ?
                                "Agregar"
                                :
                                "Actualizar"
                        }
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default ModalCategoryPeople;