import { useState, useEffect } from "react";
import ReactDOMServer from 'react-dom/server';

import IconButton from "../IconButton";
import { Modal } from "react-bootstrap";

const FieldTypeTable = ({ param, location, value, onChange }) => {
    const [show, setShow] = useState(false);

    return (
        <div role="button" className="col px-2 pt-1 rounded-2 bg-light-green" style={{ maxHeight: "35px" }}>
            <div className="row">
                <div className="col">
                    <i className="fa-solid fa-table text-purple me-2"></i> <span className="fs-6">Completa la tabla</span>
                </div>
                <div className="col-2">
                    <IconButton
                        title="Llenar tabla"
                        icon="fa-solid fa-file-pen text-green"
                        className="pt-0"
                        onClick={() => setShow(true)}
                    />
                </div>
            </div>
            <ModalTableField param={param} show={show} setShow={setShow} value={value} onChange={onChange} />
        </div>
    )
}

const ModalTableField = ({ param, show, setShow, value, onChange }) => {

    return (
        <Modal show={show} onHide={() => setShow(false)} size="xl">
            <Modal.Header className='bg-light-green pe-4' closeButton>
                <div className='container'>
                    <div className='row'>
                        <div className='col-1'>
                            <i className="fa-solid fa-file-pen mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                        </div>
                        <div className='col'>
                            <h5 className="mt-2">
                                {param.name}
                            </h5>
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <DynamicTable
                    columns={(() => {
                        let cols = param.value_select.split("*")
                        cols.pop()
                        return cols
                    })()}
                    rowValues={value}
                    onChange={(values, type) => {
                        const { id, ...other } = param;

                        if (type === "ROWS") {
                            onChange({
                                parameterization_id: param.id,
                                value: "",
                                table: values,
                                ...other,
                            });
                            return;
                        }
                        onChange({
                            parameterization_id: param.id,
                            value: values,
                            ...other,
                        });
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-success mx-auto rounded-3" onClick={() => setShow(false)}>
                    <i className="fa-solid fa-paper-plane me-2" style={{ fontSize: '16px' }}></i>
                    Listo
                </button>
            </Modal.Footer>
        </Modal>
    )
}

const DynamicTable = ({ columns = [], onChange, rowValues = [] }) => {
    const eliminarFila = (index) => {
        let tempRows = [...rowValues];
        tempRows.splice(index, 1);
        onChange(tempRows, "ROWS");
    };

    const agregarFila = () => {
        let rows = [...rowValues, {}];
        onChange(rows, "ROWS");
    };

    const editarCelda = (index, columna, valor) => {
        let row = { ...rowValues[index] };
        row[columna] = valor;
        let rows = rowValues.map((item, i) => {
            if (i === index) return row;
            return item
        });
        onChange(rows, "ROWS");
    };

    const tablaHTML = (
        <div className="d-block rounded-3 clip-hide">
            <table className="table table-responsive table-header-custom table-striped fs-6">
                <thead>
                    <tr>
                        {
                            columns.map(col => <th key={col}>{col}</th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {rowValues.map((fila, index) => (
                        <tr key={index}>
                            {
                                columns.map(col => <td key={col} className="p-0">
                                    {fila[col]}
                                </td>)
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    const tablaHTMLString = ReactDOMServer.renderToString(tablaHTML);

    useEffect(() => {
        onChange(tablaHTMLString, "HTML_TABLE");
    }, [tablaHTMLString]);

    return (
        <div className="d-block rounded-3 clip-hide">
            <table className="table table-responsive table-header-custom table-striped fs-6">
                <thead>
                    <tr>
                        <th></th>
                        {
                            columns.map(col => <th key={col}>{col}</th>)
                        }
                        <th className="text-center">ACCIONES</th>
                    </tr>
                </thead>
                <tbody>
                    {rowValues.map((fila, index) => (
                        <tr key={index}>
                            <td></td>
                            {
                                columns.map(col => <td key={col} className="p-0">
                                    <input
                                        type="text"
                                        value={fila[col] ?? ""}
                                        placeholder="Digita un valor"
                                        style={{ border: "none", backgroundColor: "transparent" }}
                                        className="form-control h-100 w-100 mt-2"
                                        onChange={(e) => editarCelda(index, col, e.target.value)}
                                    />
                                </td>)
                            }

                            <td>
                                <IconButton
                                    icon="fa-solid fa-trash text-red"
                                    title="Eliminar fila"
                                    className="d-block mx-auto"
                                    onClick={() => eliminarFila(index)}
                                />
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={4}>
                            <IconButton
                                icon="fa-solid fa-plus-circle text-green"
                                title="Agregar fila"
                                className="d-block mx-auto"
                                onClick={agregarFila}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}


export default FieldTypeTable;