import { createSlice } from '@reduxjs/toolkit';
import { CHECK_CODES, ID_NOT_DEFINED, IS_A_CATEGORY } from '../../constant/constant';
import { extractQuestionCodes } from '../../modules/DocumentsAuto/utils/functions';


const initialState = {
    dataDocument: {
        id: 0,
        name: "",
        description: "",
        roles: [],
    },
    dataLabel: {
        id: 0,
        actors_documents_id: 0,
        name: "",
    },

    listDocuments: [],
    fieldsTypePeople: [],
    fieldsPeopleTable: [],
    favoriteCodes: [],
    favoriteCodesTable: [],
    dataSearchPeople: {},
    listFoundPeople: [],

    // ------------------------------------
    list: [],
    filtered: [],
    listQuestions: [],
    codesQuestions: [],
    codesActors: [],
    codesGlobal: [],
    listActorsSelected: [],
    selectedQuestion: {
        id: 0,
        name: "",
        documents_auto_id: 0,
        type: "QUESTION_CLOSED",
        first_question: true,
        finish: false,
    },
    selectedAnswer: {
        id: 0,
        name: "",
        answer: "",
        questions_documents_id: 0,
        finish: false,
    },
    refreshQuestions: true,
    refreshActors: true,
    selectedPeople: [],
    selectedPerson: {},
    checkCodes: CHECK_CODES,
    generateDocument: {
        id: 0,
        needs_zapsign: false,
        needs_another_template: false,
        actors: [],
        answers: [],
    },
    totalLabels: 0,
    selectedGenerateAnswer: {},
    listAnswers: [],
}
export const documentsAutoSlice = createSlice({
    name: 'documentsAutoSlice',
    initialState,
    reducers: {
        resetCache: (state) => {
            Object.keys(state).forEach(key => {
                state[key] = initialState[key]; // Donde initialState es el objeto que contiene los valores predeterminados
            });
        },
        setListDocuments: (state, action) => {
            state.listDocuments = action.payload.value;
        },
        setListQuestions: (state, action) => {
            state.listQuestions = action.payload.value;

            let questions = [];
            action.payload.value.forEach(element => {
                questions = [...questions, ...extractQuestionCodes(element)];
            });

            state.codesQuestions = questions;
        },
        reducerForm: (state, action) => {
            state.dataDocument[action.payload.key] = action.payload.value
        },
        reducerFormQuestion: (state, action) => {
            state.selectedQuestion[action.payload.key] = action.payload.value;
        },
        reducerFormAnswer: (state, action) => {
            state.selectedAnswer[action.payload.key] = action.payload.value;
        },
        setSelectedQuestion: (state, action) => {
            state.selectedQuestion = action.payload.value ? action.payload.value : {
                id: 0,
                name: "",
                documents_auto_id: 0,
                type: "QUESTION_CLOSED",
                first_question: true,
                finish: false,
            };
        },
        setSelectedAnswer: (state, action) => {
            state.selectedAnswer = action.payload.value ? action.payload.value : {
                id: 0,
                name: "",
                answer: "",
                questions_documents_id: 0,
                finish: false,
            };
        },
        cleanQuestion: (state, action) => {
            state.selectedQuestion = {
                id: 0,
                name: "",
                documents_auto_id: 0,
                type: "QUESTION_CLOSED",
                first_question: true,
                finish: false
            };
        },
        cleanAnswer: (state, action) => {
            state.selectedAnswer = {
                id: 0,
                name: "",
                answer: "",
                questions_documents_id: 0,
                finish: false,
            };
        },
        setRefresQuestions: (state, action) => {
            state.refreshQuestions = !state.refreshQuestions;
        },
        setRefreshActors: (state, action) => {
            state.refreshActors = !state.refreshActors;
        },
        // Filtra de los campos favoritos el campo del select que está activo, y guarda su valor en el objeto dataSearchPeople
        reducerFormSearch: (state, action) => {
            let { value, key } = action.payload
            const obj = state.dataDocument.field_favorite_id.split("--")

            if (obj.length === 3) {
                state.dataSearchPeople = {
                    ...state.dataSearchPeople,
                    [key]: obj[2] === IS_A_CATEGORY ? {
                        parameterization_category_peoples_id: parseInt(obj[0] ?? 0),
                        value,
                    } : {
                        parameterization_type_peoples_id: parseInt(obj[0] ?? 0),
                        value,
                    },
                }
            }
        },
        cleanData: (state, action) => {
            state.dataDocument = {
                id: 0,
                description: "",
                name: "",
                roles: [],
            }
        },
        setFieldsTypePeople: (state, action) => {
            state.fieldsTypePeople = action.payload.value;
        },
        setFieldsPeopleTable: (state, action) => {
            state.fieldsPeopleTable = action.payload.value;
        },
        setFavoriteCodes: (state, action) => {
            state.favoriteCodes = action.payload.value;
        },
        setStateFavorite: (state, action) => {
            const { id, value, favorite_id, is_category = false } = action.payload;

            if (!is_category) {
                state.fieldsTypePeople = state.fieldsTypePeople.map(code => {
                    if (code.id === id && !code.category_peoples_id) {
                        return {
                            ...code,
                            state: value,
                            favorite_id,
                        }
                    }
                    return code;
                });
                return;
            }
            state.fieldsTypePeople = state.fieldsTypePeople.map(code => {
                if (code.id === id && code.category_peoples_id) {
                    return {
                        ...code,
                        state: value,
                        favorite_id,
                    }
                }
                return code;
            });
        },
        setFavoriteCodesTable: (state, action) => {
            state.favoriteCodesTable = action.payload.value;
        },
        setStateFavoriteCodesTable: (state, action) => {
            const { id, value, favorite_id, is_category = false } = action.payload;

            if (!is_category) {
                state.fieldsPeopleTable = state.fieldsPeopleTable.map(code => {
                    if (code.id === id && !code.category_peoples_id) {
                        return {
                            ...code,
                            state: value,
                            favorite_id,
                        }
                    }
                    return code;
                });
                return;
            }
            state.fieldsPeopleTable = state.fieldsPeopleTable.map(code => {
                if (code.id === id && code.category_peoples_id) {
                    return {
                        ...code,
                        state: value,
                        favorite_id,
                    }
                }
                return code;
            });
        },
        setListFoundPeople: (state, action) => {
            state.listFoundPeople = action.payload.value;
        },
        setPeopleId: (state, action) => {
            state.dataDocument.people_id = action.payload.value;
        },
        setCheckCodes: (state, action) => {
            state.checkCodes = action.payload.value;
        },
        setSelectedDocument: (state, action) => {
            state.dataDocument = action.payload.value;
        },

        // Roles
        addRole: (state, { payload }) => {
            const { id, role } = payload;
            if(state.dataDocument.roles && state.dataDocument.roles.length > 0) {
                state.dataDocument.roles.push(role);
            } else {
                state.dataDocument.roles = [role];
            }

            state.list = state.list.map(document => {
                if (document.id === id) {
                    document.roles = [...(document.roles ?? []), role];
                    return document
                }
                return document
            });
            state.filtered = state.filtered.map(document => {
                if (document.id === id) {
                    document.roles = [...(document.roles ?? []), role];
                    return document;
                }
                return document;
            });
        },
        removeRole: (state, { payload }) => {
            const { id, role } = payload;

            state.dataDocument.roles = state.dataDocument.roles?.filter(itemRole => itemRole.id !== role.id);

            state.list = state.list.map(document => {
                if (document.id === id) {
                    document.roles = document.roles?.filter(itemRole => itemRole.id !== role.id);
                    return document
                }
                return document
            });
            state.filtered = state.filtered.map(document => {
                if (document.id === id) {
                    document.roles = document.roles?.filter(itemRole => itemRole.id !== role.id);
                    return document;
                }
                return document;
            });
        },
        // ---------------------------------------------------------------
        setListParametrizeDocuments: (state, action) => {
            action.payload.value.sort((a, b) => a.name.localeCompare(b.name));
            state.list = action.payload.value;
            state.filtered = action.payload.value;
        },
        deleteFromParametrizeDocuments: (state, action) => {
            const newList = state.list.filter(e => e.id !== action.payload.value)
            state.list = newList;
            state.filtered = newList;
        },
        updateFromParametrizeDocuments: (state, action) => {
            const document = action.payload.value;

            if (state.list.findIndex(e => e.id === document.id) === -1) {
                state.list.push(document);
                state.filtered.push(document);
                return;
            }

            const modificadaList = state.list.map((e) => e.id === document.id ? document : e);
            const modificadaListFilter = state.filtered.map((e) => e.id === document.id ? document : e);

            state.list = modificadaList;
            state.filtered = modificadaListFilter;
        },
        insertFromParatrizeDocuments: (state, action) => {
            state.list.push(action.payload.value);
            state.filtered.push(action.payload.value);
        },
        searchFromParatrizeDocuments: (state, action) => {
            if (action.payload.value === "") {
                state.filtered = state.list;
                return;
            }
            const filtered = state.list.filter(e => e.name.toLowerCase().includes(action.payload.value.toLowerCase()))
            state.filtered = filtered;
        },
        setByIdFromParatrizeDocuments: (state, action) => {
            const document = action.payload.value;
            state.dataDocument = {
                ...state.dataDocument,
                document_id: document.id,
                name_document: document.name,
                description_document: document.description,
                url_document: document.url,
            }
        },
        setDataURL: (state, action) => {
            state.dataDocument = {
                ...state.dataDocument,
                url_document: action.payload.value,
            };
        },
        addActor: (state, action) => {
            state.listActorsSelected.push(action.payload.value);
        },
        deleteActor: (state, action) => {
            state.listActorsSelected = state.listActorsSelected.filter(e => e.id !== action.payload.value);
        },
        setListActorsSelected: (state, action) => {
            state.listActorsSelected = action.payload.value;

            let totalLabels = 0;
            state.listActorsSelected.forEach(actor => {
                totalLabels += actor.labels?.length;
            });

            state.totalLabels = totalLabels;
        },
        setCodes: (state, action) => {
            switch (action.payload.type) {
                case "CODES_QUESTION":
                    state.codesQuestions = action.payload.value;
                    break;
                case "CODES_ACTOR":
                    state.codesActors = action.payload.value;
                    break;
                case "CODES_GLOBAL":
                    state.codesGlobal = action.payload.value;
                    break;
                default:
                    break;
            }
        },
        setSelectedActors: (state, action) => {
            state.selectedPeople = action.payload.value ?? [];
            if (state.selectedPeople.length > 0) {
                state.selectedPerson = state.selectedPeople[0];
            }
        },
        addSelectedActors: (state, action) => {
            const person = action.payload.value;
            if (!state.selectedPeople.some(item => item.people.id === person.people.id)) {
                state.selectedPeople.push(action.payload.value);
            }
        },
        updateSelectedActors: (state, action) => {
            const person = action.payload.value;
            state.selectedPeople = state.selectedPeople.map(item => {
                if (item.people_id === person.people_id) return person;
                return item;
            });
        },
        deleteSelectedActors: (state, action) => {
            state.selectedPeople = state.selectedPeople.filter(actor => actor.people_id !== action.payload.value);
        },
        updateSelectedLabelActor: (state, action) => {
            const { person, label } = action.payload.value;

            state.selectedPeople = state.selectedPeople.map(item => {
                if (item.people.id === person.people.id) return {
                    ...item,
                    labels: label,
                    label_global_id: label.id,
                };

                return item;
            })
        },
        setSelectedPerson: (state, action) => {
            state.selectedPerson = action.payload.value;
        },
        // Save data from actor when save the content from form
        updateOnePersonFromPeople: (state, action) => {
            state.selectedPeople = action.payload.value;
        },
        reducerFormSelectedPerson: (state, { payload }) => {
            state.selectedPerson[payload.key] = payload.value;
            // Updates actor in list too
            state.selectedPeople = state.selectedPeople.map(person => {
                if (person.people_id === state.selectedPerson.people_id) {
                    return state.selectedPerson;
                }
                return {
                    ...person,
                    require_selfie_photo: state.selectedPerson.require_selfie_photo,
                    require_document_photo: state.selectedPerson.require_document_photo,
                    automatic_mailing: state.selectedPerson.automatic_mailing,
                    automatic_whatsapp: state.selectedPerson.automatic_whatsapp,
                }
            });
        },
        changeZapsignOptionPeople: (state, action) => {
            const listConfigurations = action.payload.value;

            state.selectedPeople = state.selectedPeople.map(person => {
                return {
                    ...person,
                    require_selfie_photo: listConfigurations[0].active,
                    require_document_photo: listConfigurations[1].active,
                    automatic_whatsapp: listConfigurations[2].active,
                    automatic_mailing: listConfigurations[3].active,
                }
            });

            state.selectedPerson = {
                ...state.selectedPerson,
                require_selfie_photo: listConfigurations[0].active,
                require_document_photo: listConfigurations[1].active,
                automatic_whatsapp: listConfigurations[2].active,
                automatic_mailing: listConfigurations[3].active,
            };
        },
        reducerFormGenerateDocument: (state, action) => {
            state.generateDocument[action.payload.key] = action.payload.value;
        },
        setGenerateDocument: (state, action) => {
            state.generateDocument = action.payload.value ?? {
                id: 0,
                needs_zapsign: false,
                needs_another_template: false,
                actors: [],
                answers: [],
            };

            if(state.generateDocument.template_email_id && state.generateDocument.template_email_id !== ID_NOT_DEFINED) {
                state.generateDocument.needs_another_template = true;
            }
        },
        setListAnswers: (state, action) => {
            state.listAnswers = action.payload.value;
        },
        addListAnswers: (state, {payload}) => {
            console.log({list: state.listAnswers.map(item=>({...item})), payload})
            if (!state.listAnswers.some(item => item.code_questions_id === payload.code_questions_id)) {
                state.listAnswers.push(payload);
            }
        },
        deleteListAnswers: (state, action) => {
            state.listAnswers = state.listAnswers.filter(item => item.code_questions_id !== action.payload.value);
        },
        setSelectedGenerateAnswer: (state, action) => {
            state.selectedGenerateAnswer = action.payload.value;
        },
        updateGenerateAnswer: (state, action) => {
            const answer = action.payload.value;
            state.listAnswers = state.listAnswers.map(item => {
                if (item.code_questions_id === answer.code_questions_id) return answer;
                return item;
            });
        },
        reducerSelectedGenerateAnswer: (state, action) => {
            state.selectedGenerateAnswer[action.payload.key] = action.payload.value;
        },
    }
})

export const {
    setListDocuments,
    reducerForm,
    cleanData,
    setFieldsTypePeople,
    setFieldsPeopleTable,
    setFavoriteCodes,
    setStateFavorite,
    setFavoriteCodesTable,
    setStateFavoriteCodesTable,
    reducerFormSearch,
    setListFoundPeople,
    setPeopleId,
    setCheckCodes,
    setSelectedDocument,
    addRole,
    removeRole,
    setListParametrizeDocuments,
    deleteFromParametrizeDocuments,
    updateFromParametrizeDocuments,
    insertFromParatrizeDocuments,
    searchFromParatrizeDocuments,
    setByIdFromParatrizeDocuments,
    setDataURL,

    setListActorsSelected,
    addActor,
    deleteActor,
    reducerFormQuestion,
    reducerFormAnswer,
    setListQuestions,
    setSelectedQuestion,
    setSelectedAnswer,
    setRefresQuestions,
    setRefreshActors,
    cleanQuestion,
    cleanAnswer,
    setCodes,
    setSelectedActors,
    addSelectedActors,
    updateSelectedActors,
    deleteSelectedActors,
    updateSelectedLabelActor,
    setSelectedPerson,
    reducerFormSelectedPerson,
    updateOnePersonFromPeople,
    reducerFormGenerateDocument,
    setGenerateDocument,
    setListAnswers,
    addListAnswers,
    deleteListAnswers,
    setSelectedGenerateAnswer,
    updateGenerateAnswer,
    reducerSelectedGenerateAnswer,
    changeZapsignOptionPeople,
    resetCache,
} = documentsAutoSlice.actions

export default documentsAutoSlice.reducer