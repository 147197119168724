import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import IconButton from "../../../app/components/IconButton";

import { setSelectedTypepeople } from "../../../reducers/typePeople/reducerTypepeople";
import { setRefreshTable } from "../../../reducers/people/reducerPeople";
import confirmDelete from "../../../utils/confirmDelete";
import { MessageError, MessageSuccess } from "../../../utils/message";
import GeneralService from "../../../services/GeneralService";
import { useLoadFavoritePeople } from "../../Configuration/hooks/useLoadFavoritePeople";
import people from "../assets/people.svg";
import { toast } from "../../../utils/toast";
import { ID_NOT_DEFINED } from "../../../constant/constant";
import { deleteSelectedActors } from "../../../reducers/documents/reducerGenerateDocument";

const DynamicTable = ({
  actionName = "",
  forParameterization = true,
  onPersonSelected,
}) => {
  const { list, selectedTypepeople } = useSelector(state => state.typepeopleSlice);
  const { dataTypePeole, listFoundPeople, fieldsPeopleTable } = useSelector(state => state.peopleSlice);
  const dispatch = useDispatch();
  const history = useHistory();
  useLoadFavoritePeople();

  const [selectedPeople, setSelectedPeople] = useState([]);
  const togglePersonSelection = (person) => {
    if (selectedPeople.includes(person.id)) {
      // Deseleccionar persona
      setSelectedPeople((prev) => prev.filter((id) => id !== person.id));
      removeActor(person.id);
    } else {
      // Seleccionar persona
      setSelectedPeople((prev) => [...prev, person.id]);
      onPersonSelected({
        ...person,
        type_people: selectedTypepeople,
      });
    }
  };

  const removeActor = async (id) => {
    if (id !== ID_NOT_DEFINED) {
      const service = new GeneralService("actors-generate-document");
      const res = await service.delete(id);
      if (!res.is_ok) {
        MessageError(res.message, service.status);
        return;
      }
    }
    toast(dispatch, "Actor removido");
    dispatch(deleteSelectedActors({ value: id }));
  };

  const filtered = fieldsPeopleTable.filter((item) => item.state);

  const deletePeople = async (id) => {
    const service = new GeneralService("people");
    const res = await service.delete(id);
    if (res.is_ok) {
      MessageSuccess("La persona ha sido eliminada exitosamente!");
      dispatch(setRefreshTable());
      return;
    }
    MessageError("No fue posible eliminar la persona.");
  };

  useEffect(() => {
    dispatch(
      setSelectedTypepeople({
        value: list.find(
          (item) => item.id === parseInt(dataTypePeole.type_people_id)
        ),
      })
    );
  }, [dataTypePeole.type_people_id]);

  return (
    <div className="col-12 table-data">
      <div className="order">
        {parseInt(dataTypePeole.type_people_id) === 0 ||
          filtered.length === 0 ? (
          <div className="svgtable" role="alert">
            <img src={people} />
            No hay resultados para tú busqueda.
          </div>
        ) : (
          <div className="divxscroll d-block rounded-3 clip-hide">
            <table className={`table table-header-custom ${listFoundPeople.length > 0 && ""}`}>
              <thead>
                <tr className="font-lato">
                  {filtered.map((item, i) => {
                    if (i === 0) {
                      return (
                        <Fragment key={item.id}>
                          <th></th>
                          <th>#</th>
                          <th className="fs-6">{item.name}</th>
                        </Fragment>
                      );
                    }
                    return (
                      <th className="fs-6" key={item.id}>
                        {item.name}
                      </th>
                    );
                  })}
                  <th className="fs-6">{actionName}</th>
                </tr>
              </thead>
              <tbody>
                {listFoundPeople.map((item, i) => (
                  <tr
                    key={item.id}
                    className=" tabletr"
                    onDoubleClick={() => togglePersonSelection(item)}
                  >
                    <td></td>
                    <td>{i + 1}.</td>
                    {item.filtered.map((data) => (
                      <td key={data.id}>{data.value}</td>
                    ))}
                    <td>
                      {forParameterization ? (
                        <>
                          <IconButton
                            icon="fa-solid fa-pen-to-square text-green"
                            title="Editar persona"
                            onClick={() =>
                              history.push(`/people/edit/${item.id}`)
                            }
                          />
                          <IconButton
                            icon="fa-solid fa-trash-can text-red"
                            title="Eliminar persona"
                            onClick={async () =>
                              await confirmDelete(() => deletePeople(item.id))
                            }
                          />
                        </>
                      ) : (
                        <td>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedPeople.includes(item.id)}
                            onChange={() => togglePersonSelection(item)}
                          />
                        </td>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default DynamicTable;
