import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

import GeneralService from "../../../services/GeneralService";
import { useHandleActors } from "../../DocumentsAuto/components/generate/useHandleActors";
import { SelectAndCreate } from "../../TercerosCreate/components/SelectAndCreate";
import service from "../services/servicePeople";
import { reducerForm, reducerFormSearch, resetDataSearchPeople, setListFoundPeople } from "../../../reducers/people/reducerPeople";
import { TOAST_INFO, HTTP_NO_CONTENT, ID_NOT_DEFINED, } from "../../../constant/constant";
import { extractFieldsTable } from "../../DocumentsAuto/utils/functions";
import { MessageInfo, toast } from "../../../utils";

export const SearchBarPeople = ({ forParameterization = true, pagination, setPagination }) => {
  const {
    dataTypePeole,
    dataSearchPeople,
    fieldsTypePeople,
    refreshTable,
    fieldsPeopleTable,
  } = useSelector((state) => state.peopleSlice);
  const { activeTypePeople } = useHandleActors();
  const dispatch = useDispatch();
  const history = useHistory();

  const filtered = fieldsPeopleTable.filter((item) => item.state);

  const setDataInput = (e) => {
    dispatch(
      reducerFormSearch({
        key: e.target.name,
        value: e.target.value,
      })
    );
  };

  const setData = async (e) => {
    dispatch(
      reducerForm({
        key: e.target.name,
        value: e.target.value,
      })
    );
  };

  const handleConfiguration = () => {
    history.push({
      pathname: "/configurations",
      search: `?tabIndex=1`,
    });
  };

  const searchPeople = async () => {
    if (parseInt(dataTypePeole.type_people_id) === ID_NOT_DEFINED) {
      toast(dispatch,"No se ha seleccionado el tipo de persona",8000,TOAST_INFO);
      return;
    }

    const keys = Object.keys(dataSearchPeople);
    const list = [];

    keys.forEach((e) => {
      if (dataSearchPeople[e].parameterization_id && dataSearchPeople[e].value !== "") {
        list.push(dataSearchPeople[e]);
      }
    });

    if (list.length > 0) {
      const res = await service.findPeople({ parameterizations: list });
      if (res.data.is_ok) {
        const { people = [] } = res.data;
        const proccess = extractFieldsTable(people, filtered);
        dispatch(setListFoundPeople({ value: proccess }));
        return;
      }
      if (res.status === HTTP_NO_CONTENT) {
        MessageInfo("No se encontró ningún tercero");
        dispatch(setListFoundPeople({ value: [] }));
      }
    } else {
      await loadAll();
    }
  };

  // Resetea el campo de búsqueda para hacer que el refresh de la tabla cuando se elimine sea llamando este método el cual lista todos los terceros.
  const loadAll = async () => {
    if (parseInt(dataTypePeole.type_people_id) === ID_NOT_DEFINED) {
      toast(dispatch, "No se ha seleccionado el tipo de persona", 8000, TOAST_INFO);
      return;
    }

    dispatch(resetDataSearchPeople());
    const service = new GeneralService("people");
    const res = await service.getList(1000, {
      type_peoples_id: dataTypePeole.type_people_id,
      ...pagination,
    });
    const { people = [], total_pages = 0 } = res;
    const proccess = extractFieldsTable(people, filtered);
    dispatch(setListFoundPeople({ value: proccess }));
    setPagination({...pagination, total_pages});
  };

  useEffect(() => {
    searchPeople();
  }, [refreshTable, pagination.page, pagination.page_size]);

  return (
    <div className="search-bar rounded-3 p-2">
      <div className="row container-search align-items-center g-3">
        {/* Dropdown SelectAndCreate */}
        <div className="col-xxl-3 col-xl-3 col-md-3 col-sm-11 col-12">
          <SelectAndCreate
            activeTypePeople={activeTypePeople}
            showCreateButton={false}
            showCreateLinkButton={false}
          />
        </div>

        {/* Dropdown de selección */}
        <div className="  col-xxl-3 col-xl-3 col-md-3 col-sm-11 col-12">
          <select
            className="  form-select"
            name="field_favorite_id"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #ced4da",
              height: "44px",
            }}
            value={dataTypePeole.field_favorite_id}
            onChange={setData}
          >
            <option value="0">Seleccione</option>
            {fieldsTypePeople
              .filter((item) => item.state)
              .map((item) => (
                <option
                  value={`${item.id}--${item.name}--${item.category_peoples_id ? 1 : 0
                    }`}
                  key={uuidv4()}
                >
                  {item.name}
                </option>
              ))}
          </select>
        </div>

        {/* Input para el dato */}
        <div className="div-search col-xxl-3 col-xl-3 col-md-3 col-sm-11 col-12">
          <input
            type="text"
            name={dataTypePeole.field_favorite_id.split("--")[1] ?? "none"}
            className="form-control  "
            placeholder={
              dataTypePeole.field_favorite_id === ""
                ? "Selecciona un campo favorito"
                : "Digita el dato"
            }
            required
            value={
              dataSearchPeople[dataTypePeole.field_favorite_id.split("--")[1]]
                ?.value ?? ""
            }
            autoComplete="off"
            onChange={setDataInput}
            readOnly={dataTypePeole.field_favorite_id === ""}
            onKeyUp={(e) => {
              if (e.key === "Enter") searchPeople();
            }}
            style={{ padding: "10px" }}
          />
        </div>
        {/* Botón de búsqueda */}
        <div className=" col-xxl-2 col-xl-2 col-md-2 col-sm-11 col-12">
          <Button
            title="Haz la búsqueda con los parámetros especificados"
            className="btn  btn-success  col-12 "
            onClick={searchPeople}
          >
            <i
              className="fa-solid fa-magnifying-glass"
              style={{ fontSize: "20px" }}
            ></i>
          </Button>
        </div>
      </div>
    </div>
  );
};
