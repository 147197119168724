import { useSelector } from 'react-redux';

import BreadcrumbCustom from "../../../../app/components/BreadcrumCustom";
import CardProcess from "../../../../app/components/CardProcess";
import TabActors from "./TabActors";
import TabDialogs from "./TabDialogs";
import TabSending from "./TabSending";
import TabText from "./TabText";
import "../../styles/generate-documents.css";

import { useGenerateDocument } from "./useGenerateDocument";
import { ID_NOT_DEFINED } from '../../../../constant/constant';

const GenerateDocumentAuto = () => {
    const { generateDocument } = useSelector(state => state.documentsAutoSlice);
    const { dataDocument, setCurrentStep, currentStep } = useGenerateDocument();
    
    return (
        <div className="container-fluid max-height-overflow-y mx-2">
            <div className="">
                <div className="row m-0 p-0">
                    <div className="col-11 p-0">
                        <BreadcrumbCustom
                            title="Generar documento"
                            routes={[
                                { name: "Documentos", link: "/documents-auto/list" },
                            ]}
                            activeRoute={dataDocument.name ? dataDocument.name : "Seleccione un documento"}
                        />
                    </div>
                </div>
             <div style={{backgroundColor: "white" }}> 
                <div className="row rowcont">
                    <CardProcess
                        icon="fa-user-group"
                        iconColor="text-gray"
                        title="Actores"
                        subtitle="Número de personas que participan en la ejecución del documento"
                        order={1}
                        icon_style="bg-blue text-white fa-light"
                        onClick={() => setCurrentStep(1)}
                        useIcon={true}
                        currentStep={currentStep}
                    />
                    <CardProcess
                        icon="fa-paper-plane"
                        iconColor="text-gray"
                        title="Firmas"
                        subtitle="Configura la forma de envío y firma de tus clientes"
                        order={2}
                        icon_style="bg-orange text-white"
                        useIcon={true}
                        onClick={() => setCurrentStep(2)}
                        currentStep={currentStep}
                    />
                    <CardProcess
                        icon="fa-regular fa-circle-question"
                        iconColor="text-gray"
                        title="Diálogos"
                        subtitle="Aquí puedes responder al cuestionario siguiendo la secuencia"
                        order={3}
                        icon_style="bg-green text-white"
                        useIcon={true}
                        onClick={() => setCurrentStep(3)}
                        currentStep={currentStep}
                        />
                    <CardProcess
                        icon="fa-light fa-circle-play"
                        iconColor="text-gray"
                        title="Texto"
                        subtitle="Redacción del contenido general del documento y sus respectivos códigos"
                        order={4}
                        currentStep={currentStep}
                        className=""
                        icon_style="bg-blue text-white"
                        onClick={generateDocument.id !== ID_NOT_DEFINED ? () => setCurrentStep(4) : ()=>{}}
                    />
                </div>
            </div>
                <div className="row">
                    <div className="col-12">
                        {currentStep === 1 && <TabActors setCurrentStep={setCurrentStep} />}
                        {currentStep === 2 && <TabSending setCurrentStep={setCurrentStep} />}
                        {currentStep === 3 && <TabDialogs setCurrentStep={setCurrentStep} />}
                        {currentStep === 4 && <TabText />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GenerateDocumentAuto;
