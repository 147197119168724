import { CustomEmailConfiguration } from "./components/CustomEmailConfiguration";
import { TemplateEmail } from "./components/TemplateEmail";

const TabEmail = () => {
  return (
    <div className="animate__animated animate__fadeIn">
      <TemplateEmail />
      <div className="pt-3"></div>
      <CustomEmailConfiguration />
    </div>
  );
};

export default TabEmail;
