import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../parametrize/style/tree.css";
import { TOAST_DANGER, ID_NOT_DEFINED, TYPE_ANSWER } from "../../../../constant/constant";
import { addListAnswers, deleteListAnswers, setSelectedGenerateAnswer, updateGenerateAnswer } from "../../../../reducers/documents/reducerGenerateDocument";
import IconButton from "../../../../app/components/IconButton";
import confirmDelete from "../../../../utils/confirmDelete";
import DialogsBySteps from "./DialogsBySteps";
import { toast } from "../../../../utils/toast";
import GeneralService from "../../../../services/GeneralService";
import { MessageError } from "../../../../utils/message";

const TabDialogs = ({ canEdit = true, setCurrentStep }) => {
    const { listQuestions, listAnswers } = useSelector(state => state.documentsAutoSlice);
    const [selected, setSelected] = useState({});
    const [expandedAnswers, setExpandedAnswers] = useState({});
    const [editingAnswer, setEditingAnswer] = useState(null);
    const [editedAnswerText, setEditedAnswerText] = useState("");
    const [currentStepDialog, setCurrentStepDialog] = useState(0);
    const dispatch = useDispatch();
    const answersRef = useRef(null);

    const onTapCode = (obj, type) => {
        if (!canEdit) {
            toast(dispatch, "Ya no puedes editar estos parámetros", TOAST_DANGER);
            return;
        }
        if (listAnswers.some(answer => answer.code_questions_id === obj.code_questions_id)) {
            toast(dispatch, "La respuesta ya ha sido agregada antes", TOAST_DANGER);
            return;
        }

        if (type === TYPE_ANSWER) {
            dispatch(addListAnswers({ ...obj, type, answer_text: obj.answer, type_answer: obj.name, answers_id: obj.id, id: 0 }));
            toast(dispatch, "Respuesta agregada correctamente");
            scrollToBottom();
            return;
        }

        setSelected({
            ...obj,
            type,
            code_questions_id: obj.code_questions.id,
            answer_text: "",
            contextual_text: obj.answer,
            id: 0,
        });
    };

    const handleAnswerChange = (e) => {
        setSelected({
            ...selected,
            answer_text: e.target.value
        });
    };

    const handleSaveAnswer = () => {
        if (listAnswers.some(answer => answer.code_questions_id === listQuestions[currentStepDialog].code_questions.id)) {
            toast(dispatch, "La respuesta ya ha sido agregada antes", TOAST_DANGER);
            return;
        }

        dispatch(addListAnswers({
            ...listQuestions[currentStepDialog],
            code_questions_id: listQuestions[currentStepDialog].code_questions.id,
            question: listQuestions[currentStepDialog].name,
            answer_text: selected.answer_text,
            id: 0,
        }));
        if (currentStepDialog + 1 === listQuestions.length) return setCurrentStepDialog(c => c + 1);
        const nextQuestion = listQuestions[currentStepDialog + 1];
        setSelected({
            ...nextQuestion,
            code_questions_id: nextQuestion.code_questions.id,
            answer_text: "",
            question: nextQuestion.name,
            id: 0,
        });
        setCurrentStepDialog(c => c + 1);
        toast(dispatch, "Respuesta agregada correctamente");
        scrollToBottom();
    };

    const handleDelete = async (item) => {
        if (item.id !== ID_NOT_DEFINED) {
            const service = new GeneralService("answers-questinos-generate");
            const res = await service.delete(item.id);
            if (!res.is_ok) {
                MessageError(res.message, service.status);
                return;
            }
        }
        dispatch(deleteListAnswers({ value: item.code_questions_id }));
        toast(dispatch, "Eliminado correctamente");
    };

    const handleEdit = (item) => {
        setEditingAnswer(item.code_questions_id);
        setEditedAnswerText(item.answer_text);
    };

    const handleSaveEditedAnswer = async (item) => {
        const data = { ...item, answer_text: editedAnswerText };
        if (item.id !== ID_NOT_DEFINED) {
            const service = new GeneralService("answers-questinos-generate");
            const res = await service.update(data);
            if (!res.is_ok) {
                MessageError(res.message, service.status);
                return;
            }
        }

        dispatch(updateGenerateAnswer({ value: data }));
        setEditingAnswer(null);
        setEditedAnswerText("");
        toast(dispatch, "Respuesta editada correctamente");
    };

    const scrollToBottom = () => {
        if (answersRef.current) {
            setTimeout(() => {
                answersRef.current.scrollTop = answersRef.current.scrollHeight;
            }, 100);
        }
    };

    const toggleAnswer = (id) => {
        setExpandedAnswers(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    const handleNextStep = () => {
        setCurrentStep(c => c + 1);
    };

    return (
        <>
            <div className="process-tab">
                <div className="row">
                    <div className="col-10 col-lg-7 border-right-dashed mb-3 border">
                        <div className="row">
                            <div className="col">
                                <h5><b className="titleDiag">Diálogos</b></h5>
                            </div>
                        </div>
                        <p className="pText">Tienes {listQuestions.length} preguntas para responder. Cada una tiene su respectiva secuencia, asegúrate de responderlas en su totalidad.</p>

                        <DialogsBySteps
                            currentStep={currentStepDialog}
                            setCurrentStep={setCurrentStepDialog}
                            selected={selected}
                            setSelected={setSelected}
                            onTapCode={onTapCode}
                        />

                        {selected && (
                            <div className="answer-input-container mt-3">
                                <label className="label-question">Responde en el recuadro:</label>
                                <textarea
                                    className="form-control"
                                    placeholder="Ingresa tu respuesta..."
                                    value={selected.answer_text}
                                    onChange={handleAnswerChange}
                                    style={{ height: "150px", fontSize: "16px", padding: "15px" }}
                                />

                                <button
                                    type="button"
                                    className="btn btn-success d-block mx-auto mt-2"
                                    onClick={handleSaveAnswer}
                                >
                                    Guardar
                                </button>

                                {listAnswers.length === listQuestions.length && !selected && (
                                    <div className="alert alert-info mt-3">
                                        No hay más preguntas.
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="col-10 col-lg-5 px-3 " style={{ position: "relative" }}>
                        <div className="border">
                            <div className="row ">
                                <div className="col">
                                    <h5 className="font-lato fw-bold pb-2" style={{ color: "#198754" }}>
                                        Respuestas
                                    </h5>
                                </div>
                            </div>
                            <div ref={answersRef} className="row" style={{ maxHeight: "24.5rem", overflowY: "auto" }}>
                                <div className="col">
                                    {
                                        listAnswers.length === 0
                                            ? <div className="text-center mt-1" role="alert">
                                                <img
                                                    src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRyoN3EJxldO33cEujrDHK3bEfOjmy7ccCZajzRwViDXwdfisat"
                                                    alt="No hay respuestas"
                                                    style={{ marginTop: "130px" }}
                                                />
                                                <div>No hay respuesta en este momento</div>
                                            </div>
                                            : listAnswers.map(item => (
                                                <div key={item.code_questions_id} className="border mb-2 p-2 rounded">
                                                    <div
                                                        role="button"
                                                        onClick={() => toggleAnswer(item.code_questions_id)}
                                                        className="d-flex justify-content-between align-items-center"
                                                    >
                                                        <strong>{item.id ? item.question : item.name}</strong>
                                                        <i className={`fa-solid ${expandedAnswers[item.code_questions_id] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                                    </div>
                                                    {expandedAnswers[item.code_questions_id] && (
                                                        <div className="mt-2">
                                                            {editingAnswer === item.code_questions_id ? (
                                                                <>
                                                                    <textarea
                                                                        className="form-control"
                                                                        value={editedAnswerText}
                                                                        onChange={(e) => setEditedAnswerText(e.target.value)}
                                                                        style={{ height: "100px", fontSize: "14px", padding: "10px" }}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-success d-block mt-2 mx-auto"
                                                                        onClick={() => handleSaveEditedAnswer(item)}
                                                                    >
                                                                        Guardar cambios
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                <p>Respuesta: {item.answer_text}</p>
                                                            )}
                                                            <div className="mt-2">
                                                                <IconButton
                                                                    icon="fa-solid fa-edit text-green"
                                                                    title="Editar respuesta"
                                                                    onClick={() => handleEdit(item)}
                                                                />
                                                                <IconButton
                                                                    icon="fa-solid fa-trash text-red"
                                                                    title="Eliminar respuesta"
                                                                    onClick={async () => confirmDelete(() => handleDelete(item))}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mt-5">
                                <button
                                    type="submit"
                                    className={`btn rounded-3 w-100 py-2 ${listAnswers.length === 0 ? 'btn-secondary' : 'btn-success'}`}
                                    onClick={() => {
                                        handleNextStep();
                                    }}
                                    disabled={listAnswers.length === 0}
                                >
                                    Siguiente paso <i className="fa-solid fa-caret-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TabDialogs;
