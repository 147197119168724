import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import CardFavorite from './CardFavorite';

import { useLoadFavoritePeople } from '../hooks/useLoadFavoritePeople';
import { reducerForm, setStateFavorite } from '../../../reducers/people/reducerPeople';
import { MODEL_TYPE_PEOPLE, USE_FOR_SEARCH } from '../../../constant/constant';

const ModalFavorite = ({ show, setShow }) => {
  const { list } = useSelector(state => state.typepeopleSlice);
  const { fieldsTypePeople, dataTypePeole } = useSelector(state => state.peopleSlice);
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();
  useLoadFavoritePeople();

  const setData = async (e) => {
    dispatch(reducerForm({
      key: e.target.name,
      value: e.target.value,
    }));
  }

  return (
    <Modal show={show} onHide={handleClose} centered size='lg'>
      <Modal.Header className='bg-light-green pe-4' closeButton>
        <div className='container'>
          <div className='row'>

            <div className='col-1'>
              <i className="fa-solid fa-magnifying-glass mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
            </div>
            <div className='col mt-2'>
              <h5>Selecciona los campos a buscar</h5>
            </div>
            <div className='col me-1'>
              <select
                className="form-select input-borderless"
                name="type_people_id"
                style={{ backgroundColor: '#fafafa', border: 'none', height: '44px' }}
                value={dataTypePeole.type_people_id}
                onChange={setData}
              >
                <option value="0">
                  Selecciona tipo de persona
                </option>
                {
                  list.map(item => (<option value={item.id} key={item.id}>
                    {item.name}
                  </option>))
                }
              </select>
            </div>

          </div>
        </div>

      </Modal.Header>
      <Modal.Body>
        <div className='container-fluid'>
          {
            fieldsTypePeople.length === 0 ?
              <div className="alert alert-warning text-center mt-2" role="alert">
                No hay campos que mostrar
              </div>
              :
              <div className='row row-cols-auto abs-center'>
                {
                  fieldsTypePeople.map(field => (<CardFavorite
                    key={uuidv4()}
                    field={field}
                    is_category={field.is_category}
                    parent_id={parseInt(dataTypePeole.type_people_id)}
                    type_model={MODEL_TYPE_PEOPLE}
                    used_for={USE_FOR_SEARCH}
                    onChanged={(value) => dispatch(setStateFavorite(value))}
                  />))
                }
              </div>
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleClose}>
          Listo
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalFavorite;